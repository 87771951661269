/*Ajax loader Start*/
.loader_all_pages {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}
#circularG {
  position: absolute;
  width: 58px;
  height: 58px;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50);
  -webkit-transform: translate(-50%, -50);
  -ms-transform: translate(-50%, -50);
  -o-transform: translate(-50%, -50);
}
.circularG {
  position: absolute;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 9px;
  -o-border-radius: 9px;
  -ms-border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 0.908s;
  -o-animation-duration: 0.908s;
  -ms-animation-duration: 0.908s;
  -webkit-animation-duration: 0.908s;
  -moz-animation-duration: 0.908s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}
#circularG_1 {
  left: 0;
  top: 23px;
  animation-delay: 0.338s;
  -o-animation-delay: 0.338s;
  -ms-animation-delay: 0.338s;
  -webkit-animation-delay: 0.338s;
  -moz-animation-delay: 0.338s;
}
#circularG_2 {
  left: 6px;
  top: 6px;
  animation-delay: 0.454s;
  -o-animation-delay: 0.454s;
  -ms-animation-delay: 0.454s;
  -webkit-animation-delay: 0.454s;
  -moz-animation-delay: 0.454s;
}
#circularG_3 {
  top: 0;
  left: 23px;
  animation-delay: 0.57s;
  -o-animation-delay: 0.57s;
  -ms-animation-delay: 0.57s;
  -webkit-animation-delay: 0.57s;
  -moz-animation-delay: 0.57s;
}
#circularG_4 {
  right: 6px;
  top: 6px;
  animation-delay: 0.686s;
  -o-animation-delay: 0.686s;
  -ms-animation-delay: 0.686s;
  -webkit-animation-delay: 0.686s;
  -moz-animation-delay: 0.686s;
}
#circularG_5 {
  right: 0;
  top: 23px;
  animation-delay: 0.802s;
  -o-animation-delay: 0.802s;
  -ms-animation-delay: 0.802s;
  -webkit-animation-delay: 0.802s;
  -moz-animation-delay: 0.802s;
}
#circularG_6 {
  right: 6px;
  bottom: 6px;
  animation-delay: 0.908s;
  -o-animation-delay: 0.908s;
  -ms-animation-delay: 0.908s;
  -webkit-animation-delay: 0.908s;
  -moz-animation-delay: 0.908s;
}
#circularG_7 {
  left: 23px;
  bottom: 0;
  animation-delay: 1.024s;
  -o-animation-delay: 1.024s;
  -ms-animation-delay: 1.024s;
  -webkit-animation-delay: 1.024s;
  -moz-animation-delay: 1.024s;
}
#circularG_8 {
  left: 6px;
  bottom: 6px;
  animation-delay: 1.14s;
  -o-animation-delay: 1.14s;
  -ms-animation-delay: 1.14s;
  -webkit-animation-delay: 1.14s;
  -moz-animation-delay: 1.14s;
}
@keyframes bounce_circularG {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}
@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(1);
  }
  100% {
    -o-transform: scale(0.3);
  }
}
@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1);
  }
  100% {
    -ms-transform: scale(0.3);
  }
}
@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.3);
  }
}
@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1);
  }
  100% {
    -moz-transform: scale(0.3);
  }
}
/*Ajax loader End*/
