.swal2-container{z-index: 9999999 !important;}
.swal2-html-container{font-size: 14px !important;}
.swal2-title{font-size: 20px !important;}
.swal2-styled {font-size: 13px !important;}
img.hrt_icon {
    width: 15px;
}
i.icon-05.hart_icon {
    display: none;
}
.detailscontent .titlehead .wishlistpt{text-decoration: none;}
a.wishlistpt.active i.icon-05.hart_icon {
    display: block;
    font-size: 27px;
    color: #f64234;
}
.rating_profile p span {
    background: #fbb141;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    margin-right: 5px;
}
.infowrap {
    position: relative;
    display: inline-block;
  }
  
  .infowrap::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: white;
    color: black;
    border-radius: 3px;
    width:30%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .infowrap:hover::before {
    opacity: 1;
    visibility: visible;
  }

  .infowrap.tooltinfo-main::before{min-width: 20rem;box-shadow: 0 0 1rem rgba(0,0,0,.1);}
  /* .sa-countrymodal.open-it {
    display: flex;
    width: 62%;
    height: 71%;
} */

.small-header{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  cursor: default;
}

/* .sa-full-list-outer .default-carousel-simple{min-width: 100%;} */
.sa-full-list-outer{width: 100%;}
.sa-full-list-outer .default-carousel-simple{width: 100%;}
.sa-full-list-outer.sa-map-list-outer .sa-map-part{    height: 420px;
  position: sticky; 
  top: 19rem;}

 .sa-media-profile{padding: 0 1.5rem;} 
 .sa-media-profile .profile-btns{margin-top: 2rem;}
 .sa-list-address-wrap .sa--media-body .sa-locwrap{align-items: flex-start;}
 .sa-list-address-wrap .sa--media-body .sa-locwrap i{margin-top: .46rem;}
 .sa-footer-outer .bottom-footer .socila-icon ul li.twite-icon a i img{width: 2.4rem;}
 .locoutermain{flex-wrap: wrap;}
 .locoutermain .locitems{align-items: center;}
 .locoutermain .locitems{display: flex;align-items: center; }
 
 .locoutermain .locitems + .locitems::before{content: ""; width: .4rem; height: .4rem; border-radius: 50%;background-color: #888;display: inline-flex;margin:0 .8rem;}
 .pricelarge{font-size: 1.8rem;color:#000000;font-weight: 500;margin: .5rem 0;}
 /* .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .adlocation-bottom{    border-top: dashed 0.1rem #ccc;
  padding-top: 0.8rem; } */

  .sa-filter-list .sa-map-radio .filterselect{min-width: 14rem; text-align: left;}
  .sa-secure-wrap .filterbywrap ul{margin: 0 -2rem;    flex: 1; padding-right: 2rem;}
  
  .sa-secure-wrap .filterbywrap ul li {padding: 0 2rem;    width: auto;
    justify-content: stretch;
    flex: 1;}
  .sa-secure-wrap .filterbywrap ul li h3{margin-bottom: 1.5rem;}
  .input-range{height: 3rem;}
  .input-range__label-container{    font-size: 1rem;
    top: -4px;}

    .center-modal-default{position:fixed;left:0;z-index:999999;width:100%;top:0%;visibility:hidden;transition:ease-in-out .3s; display: flex;justify-content: center; align-items: center; height: 100%;}
.center-modal-default.open-it{visibility:visible}
.center-modal-default .shadowpt{position:fixed;top:0;left:0;width:100%;height:100%;background:#000;opacity:.66}
.center-modal-default.open-it .shadowpt{visibility:visible}
.center-modal-default .bottom-modal-content{position:relative;padding:20px 0 20px;margin:0;border-radius:12px;box-shadow:0 2px 2px rgba(0,0,0,.3);background:#fff;background:#fff;max-width: 40rem;width: 100%;} 
.center-modal-default .bottom-modal-content .close-modal{width:50px;height:50px;display:flex;justify-content:center;align-items:center;top:15px;right:10px;position:absolute;font-size:12px}
.center-modal-default .bottom-modal-content .modal-swop-header{padding:0 22px;    display: flex;
  justify-content: space-between;
  align-items: center; margin-bottom:  1rem;}
.center-modal-default .bottom-modal-content .modal-swop-header .modal-swop-title{font-size:18px;color:#000;margin-bottom: 0px;}
.center-modal-default .bottom-modal-content .modal-swop-header .modal-swop-title span{font-size:60%;text-transform:uppercase;color:rgba(31,31,31,.80);display:block;margin:0 0 10px}
.center-modal-default .bottom-modal-content .modal-swop-header .close_panel{width: 3.8rem;
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;}
  .center-modal-default .bottom-modal-content .modal-swop-header .close_panel:hover{
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  }
.center-modal-default .default-modal-body{padding:15px 20px;width:100%}
.sharemodel .default-modal-body {
  display: flex;
  justify-content: space-evenly; 
}

@media (max-width:991px) {
  .detailscontent{padding-top: 20px;}
}

/* .search-control .result{position:absolute;top:100%;width:100%;background:#fff;padding:1.5rem;max-height:60vh;overflow:auto;box-shadow:0 1rem 1rem rgba(0,0,0,.22);border-radius:1rem}
.search-control .result p{cursor:pointer;padding:.6rem 0;margin:0}
.search-control .result p:hover{color:#4c39ac} */

.autocomplete-items{align-items: center;margin: auto; width:100%;background:#fff;padding:1.5rem;max-width:85.2rem;overflow:auto;box-shadow:0 1rem 1rem rgba(0,0,0,.22);border-radius:1rem}


 /* Updated CSS for search results */
.search-control .result {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  padding: 1.5rem;
  max-height: 60vh;
  overflow: auto;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, .22);
  border-radius: 1rem;
}

.search-control .result p {
  cursor: pointer;
  padding: .6rem 0;
  margin: 0;
}

/* Highlight selected item */
.search-control .result p.selected {
  background-color: #f0f0f0; /* Background color for selected item */
  color: #333; /* Text color for selected item */
}

/* Optional: Hover effect */
.search-control .result p:hover {
  color: #4c39ac; /* Text color on hover */
} 
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show>.btn-warning.dropdown-toggle{font-size: 11px !important;}
.flex-head {
 justify-content: space-between !important;
 
}
  
.item .ad-box .ad-image{position: relative;}
.type-label{    position: absolute;
  top: 1rem;
  left: 1rem;
  background: #000;
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  color: #fff; font-size: 1.2rem;}
  .localtype-label{    position: absolute;
    top: 1rem;
    left: 1rem;
    background: #fff;
    padding: 0.2rem 0.6rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6;
    color: #000; font-size: 1.2rem;}
 
  .sa-secure-wrap .filterbywrap ul li{padding: 0 1rem;}
  .sa-secure-wrap .filterbywrap ul li:first-child{padding: 0 2rem;}
  .sa-secure-wrap .filterbywrap ul li.resetfilter-li{width: auto;flex: 0;}
  .sa-secure-wrap .filterbywrap ul li.resetfilter-li .btn{flex-shrink: 0;height: 5rem;}
  .form-group.sortprice-wrap{min-width: 20rem;width: auto;}
  .comman-side-popup .comman-popup-inner .form-group .error {
    border: 1px solid red;
}
.form-group .error {
  border: 1px solid red;
}
/* Add red border when custom checkbox has error class */
.get-in-touch-wrap .form-group.checkbox-group input.custom-checkbox.error + label span {
  border-color: red;
}


.businesspt{margin-right: 0!important;}
.businesspt a{
  text-decoration: none;
  display: flex;}
.businesspt a span{text-decoration: none;display: flex; white-space: nowrap;line-height: 1.6;margin-left: 0.8rem;font-size: 1.3rem; color: #000000;}
.businesspt a:hover span{color: #4c39ac;}

.sa-header-container .top-header .signreg.dropdown-parents{
  right: 0;
  left: auto;}
  .sa-header-container .top-header .signreg.dropdown-parents .dropdown-web-menu{min-width: 21rem;left: auto; right: 0;}
  .sa-header-container .top-header .signreg.dropdown-parents .dropdown-web-menu ul a{padding-top: 1rem;padding-bottom: 1rem;padding-left: 1.5rem;}
  .sa-header-container .top-header .signreg.dropdown-parents .dropdown-web-menu ul a i{margin-left: 0;}
.sa-header-container .top-header .signreg.dropdown-parents .dropdown-web-menu ul a span{line-height: normal;max-width: unset;
  line-height: normal;
  white-space: normal;
  overflow: inherit;}
  .sa-header-container .top-header .signreg.dropdown-parents .dropdown-web-menu ul:before{
    display: none;
  }
  .btn.btn-whatsapp{background-color: rgb(37, 211, 102);color: #fff;}
  .swopper-outer .btn-row.btn-bar-top-border.btn-row-new{display: flex;}
  .swopper-outer .btn-row.btn-bar-top-border.btn-row-new .btn{min-width: auto;flex: 1 1 auto;padding: 0 1.4rem;}
  .swopper-outer .btn-row.btn-bar-top-border.btn-row-new .btn img{max-width: 22px; margin-right: 1rem;}
  .accountbaradd{flex-flow: column;}
  .comman-side-popup .alreadybar p.orbar{margin: 1.5rem 0;}
  .startselling{margin: 1.5rem 0;}
  .locoutermain .locitems+.locitems:before{margin: 0 0.3rem;}
  .module-gallery .slick-vertical .slick-slide > div { height: auto; line-height: 0;}
  .module-gallery .sldnavside .slider-thumb{ overflow: unset;}
  .module-gallery .sldnavside .nextarrow{bottom: -0.6rem;}
  /* .module-gallery .slider-preview li img{object-fit: contain;} */
  .sldprevpt{width: 100%;}
  @media (max-width:1199px) { 
    .sa-secure-wrap .filterbywrap ul{padding-right: 0;margin-bottom: 1.5rem;}
  }
  @media (max-width:767px) {
    .pricelarge{font-size: 1.4rem;}
    .sa-filter-list .sa-map-radio .filterselect{width: 100%;}
    .sa-secure-wrap .filterbywrap ul li:last-child{padding-right: 0;}
    .sa-full-list-outer{width: inherit; padding: 0 .5rem;}
    .sa-full-list-outer.sa-map-list-outer .sa-map-part{ position: static; margin-bottom: 1.5rem;}
    .banner-bottom-div{display: none;}
  }
   
  @media (max-width:991px) {
    .sa-header-container .top-header .signreg .dropdown-web-menu{ display: none !important;}
    .sa-header-container .top-header .signreg .dropdown-web-menu.open{display: block !important;}
    .banner-bottom-div{display: none;}
  }

  @media (max-width:767px) {
ul.listing-filter-ul {
  flex-flow: column;
}

ul.listing-filter-ul li {
  width: 100% !important;
}

ul.listing-filter-ul li + li {
  margin-top: 15px;
}

/* ul.listing-filter-ul li .btn {
  width: 100% !important;
} */

.sa-secure-wrap .filterbywrap ul.listing-filter-ul li:first-child {padding: 0 1rem;}

}

.type-image:hover{cursor: pointer;}
.modal-swop-header.previewehead { position: absolute;right: 0;}
.list-of-sub-category ul li.active a{color:#4c39ac}
.list-of-sub-category ul li.active a .arrow-icon .hover-icon{display:flex}
.list-of-sub-category ul li.active a .arrow-icon .default-icon{display:none}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root.active{background-color:#f7f7f7}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root.active .main-card-box i img{transform:scale(1.08) translateY(-5px)}
.modal-swop-header.previewehead { position: absolute;right: 0;}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root.active{background: #4c39ac;}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root.active .main-card-box i img{    filter: brightness(0) invert(1);}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root.active .main-card-box .cat-title{    color: #fff;}
.list-of-sub-category ul li.active a{font-weight: 500;}

a.continuestep.subchildcat {
  color: #4c39ac;
  margin: 14px;
  font-weight: bold;
  font-size: 14px;
}
a:not([href]):not([tabindex]):hover {
  color: #4c39ac;
  text-decoration: none;
}
.addprobtnrow{padding-bottom:0}
.addproductsection{align-items:center;background:#ffe9ca;display:flex;justify-content:center;text-decoration:none!important;outline:0;padding:1.6rem;border-radius:.8rem}
.addproductsection .para-text{font-size:1.8rem;color:#333;font-weight:500;margin-right:1rem;text-decoration:none}
.addproductsection .btn{padding:0 3rem;background:#f5a638 url(../img/addprobg.png) 0 0 no-repeat;background-size:cover;text-transform:none;font-weight:500}
.addproductsection .btn img{margin-right:1rem}
@media (max-width:767px) {
  .addproductsection{flex-flow: column;}
  .addproductsection .para-text{margin: 0 0 1rem;}
}
.sa-home-banner.listbanner .home-item {
  
  text-align: center;
}
.sa-home-banner.listbanner .home-item img {
  width: auto;
  max-height: 250px;
  max-width: 100%;
}
.featuredside {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(94.47deg, rgb(103, 167, 242) 2.35%, rgb(37, 128, 235) 95.78%);
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  color: #fff;
  font-size: 1.2rem;
}
.premiumside {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(95.78deg, rgb(255, 189, 61) 2.35%, rgb(241, 162, 7) 95.78%);
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  color: #fff;
  font-size: 1.2rem;
}
.cardayside {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(95.78deg, rgb(20, 184, 166) 2.35%, rgb(19, 78, 74) 95.78%);

  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  color: #fff;
  font-size: 1.2rem;
}
.home-item.category-new-item{padding:1.5rem 0}
.home-item.category-new-item li{flex:0 0 100%;max-width:100%}
.home-item.category-new-item a{padding:2.1rem 2.2rem;border-radius:2rem;box-shadow:.15rem .26rem .6rem 0 rgba(0,0,0,.04);background-color:#fff;display:flex;align-items:center;text-decoration:none;width:100%}
.home-item.category-new-item a .iconimg{width:7rem;height:7rem;border-radius:1.4rem;background-color:#8c8c8c;margin-right:2rem;flex-shrink:0;overflow:hidden}
.home-item.category-new-item a .securecontent{font-family:Celias,sans-serif;font-size:1.6rem;font-weight:500;color:rgba(0,0,0,.99)}
.home-item.category-new-item a .securecontent .securehead{display:block}
.home-item.category-new-item a .securecontent .securetext{font-size:1.4rem;font-weight:300;letter-spacing:.02rem;color:rgba(0,0,0,.7);display:block}
.home-item.category-new-item li:hover a{box-shadow:0 0 1.6rem rgba(0,0,0,.11)}
@media (max-width: 991px) {
  .sa-header-container .top-header { 
      /* padding-left: 1.2rem;  */
      padding-left: 0.5rem
  }
}
@media (max-width: 767px) {
  .sa-header-container .top-header .signreg {
      margin-left: 0;
  }
}
.sellouterbox.sellbuttonpop {
  bottom: 80px !important;
}

.sellouterbox{ display: flex; z-index: 1;
  position: fixed; justify-content: center; align-items: center;
   /* bottom: 30px;  */
   bottom: 30px;
   left: 50%; transform: translateX(-50%);}
.sellouterbox .sellbtnouter{ display: inline-flex;
  height: 48px; 
  width: 104px; align-items: center; justify-content: center; text-decoration: none; color: #000;}
  .sellouterbox .sellbtnouter .bgsvg{ height: 100%;
    width: 100%; position: absolute;}
    .sellouterbox .sellbtnouter .innerbtn{position: relative;display: flex;
      align-items: center;
      justify-content: center;}
    .sellouterbox .sellbtnouter .innerbtn .pluseico{margin-right: 4px; line-height: 0;display: inline-flex}
    .sellouterbox .sellbtnouter .innerbtn .pluseico svg{margin-right: 0;}
    .sellouterbox .sellbtnouter .innerbtn .selltext{    font-size: 13px;
      font-weight: 700; 
      letter-spacing: .5px; 
      text-transform: uppercase; font-family: "Celias", sans-serif;line-height: 0; display: inline-flex;}
      .sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li a{text-align: left;}

      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul{flex-flow: row; flex-wrap: wrap;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul li{flex: auto; width: 50% !important;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul .rangerow{width: 50% !important;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul .viewcheckpt{width: 50%  !important; margin: 0; padding-left: 20px;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul .viewcheckpt .sa-map-radio{margin-left: auto;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul .selectandcheck{padding-left: 0;}
      .sa-secure-wrap .filterbywrap.filterbymobile .listing-filter-ul .resetfilter-li .btn{width: 100%;}

      .bottom-strep-wrap{align-items:center;background:#fff;border-top-left-radius:12px;border-top-right-radius:12px;bottom:0;box-sizing:border-box;display:flex;justify-content:space-between;margin-top:20px;padding:10px 16px;position:fixed;transition:all .5s ease;width:100%;box-shadow:0 0 22px rgba(0,0,0,.8);z-index:9}
      .bottom-strep-wrap.close-strip{transform:translateY(100%);box-shadow:none}
      .bottom-strep-wrap .leftstrip{display:flex;align-items:center}
      .bottom-strep-wrap .leftstrip .closebtn{flex-shrink:0;margin-right:8px}
      .bottom-strep-wrap .leftstrip .striplogo{margin-right:8px;flex-shrink:0}
      .bottom-strep-wrap .leftstrip .striplogo a{text-decoration:none}
      .bottom-strep-wrap .leftstrip .striplogo img{width:32px}
      .bottom-strep-wrap .leftstrip .strip-content h3{font-size:14px;letter-spacing:.1px;line-height:20px}
      .bottom-strep-wrap .leftstrip .strip-content .ratepictuer{display:flex;align-items:center}
      .bottom-strep-wrap .leftstrip .strip-content .ratepictuer p{margin:0;font-size:11px;letter-spacing:.1px;line-height:18px}
      .bottom-strep-wrap .rightstrip{padding-left:8px}
      .bottom-strep-wrap .rightstrip.downloadbtn .btn{height:36px;text-transform:none;font-weight:500}
      .bottom-strep-wrap .starpt .startratinginner{display:flex;padding:0;margin:0;flex-shrink:0;padding-right:8px}
      .bottom-strep-wrap .starpt .startratinginner li{display:inline-flex}
      .bottom-strep-wrap .starpt .startratinginner li+li{margin-left:2px}
      .bottom-strep-wrap .starpt .startratinginner li span{line-height:0}

      
      .sa-header-container .sa-menu ul .dropdown-parents.right-drop-menu .expanded-menu .sub-expanded-menu{left: auto !important; right: 100% !important;border-bottom-right-radius:0px !important;border-bottom-left-radius:6px !important}
      .sa-header-container .sa-menu ul .dropdown-parents.right-drop-menu .dropdown-web-menu{border-bottom-left-radius:0px;border-bottom-right-radius:6px}
      @media (max-width: 767px){
        .module-gallery .slider-wrapper{flex-flow: column-reverse;height: auto;}
        .module-gallery .sldnavside .slider-thumb{width: 100%;
            padding: 1.5rem 0 0;}
            .module-gallery .prevarrow {
              top: 10px;
              transform: rotate(-90deg);
          }
      .module-gallery .sldnavside .nextarrow {
         bottom: auto;
          right: 8px;
          left: auto;
          top: 10px;
          transform: rotate(-90deg);
      }
      .module-gallery .slider-thumb li {
       padding: 2px;
    }
    }