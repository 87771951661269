.transition, .categorymenu-sidebar li a, .chatlistrow .chatmenuspt, .congrulation-model .close_panel, .upload__btn, .comman-side-popup .backarrow, .comman-side-popup .close_panel, .comman-side-popup .shadowpt, .comman-side-popup .comman-popup-inner, .comman-side-popup, .sa-pagination a, .sa-countrymodal, .shadowpt, .sa-tranding-cate .sa-trending-card li a .go-icon i, .sa-tranding-cate .sa-trending-card li a .go-icon, .sa-tranding-cate .sa-trending-card li a, .sa-footer-outer .bottom-footer .socila-icon ul li a, .sa-footer-outer .bottom-footer .menu-list ul li a, .sa-header-container .sa-menu ul li a {
  transition: ease-in-out 0.3s;
}

.radio-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-outer label {
  cursor: pointer;
  display: flex;
  margin: 0;
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}
.radio-outer .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  border: 0.1rem solid rgba(36, 39, 44, 0.2);
  border-radius: 50%;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio-outer .input-radio:checked {
  box-shadow: 0px 0px 0px 0.1rem #4C39AC;
  background-color: #4C39AC;
}
.radio-outer .input-radio:checked:after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border-radius: 50%;
}

@font-face {
  font-family: "Celias";
  src: url("../fonts/Celias-Medium.woff2") format("woff2"), url("../fonts/Celias-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Celias";
  src: url("../fonts/Celias-Regular.woff2") format("woff2"), url("../fonts/Celias-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Celias";
  src: url("../fonts/Celias-Light.woff2") format("woff2"), url("../fonts/Celias-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "swop";
  src: url("../fonts/swop.eot?koq6e");
  src: url("../fonts/swop.eot?koq6e#iefix") format("embedded-opentype"), url("../fonts/swop.ttf?koq6e") format("truetype"), url("../fonts/swop.woff?koq6e") format("woff"), url("../fonts/swop.svg?koq6e#swop") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "swop" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-01:before {
  content: "\e900";
}

.icon-02:before {
  content: "\e901";
}

.icon-03:before {
  content: "\e902";
}

.icon-04:before {
  content: "\e903";
}

.icon-05:before {
  content: "\e904";
}

.icon-06:before {
  content: "\e905";
}

.icon-07:before {
  content: "\e906";
}

.icon-08:before {
  content: "\e907";
}

.icon-09:before {
  content: "\e908";
}

.icon-10 .path1:before {
  content: "\e909";
  color: rgb(0, 0, 0);
}

.icon-10 .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(235, 66, 39);
}

.icon-10 .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-11:before {
  content: "\e90c";
}

.icon-12:before {
  content: "\e90d";
}

.icon-13:before {
  content: "\e90e";
}

.icon-14:before {
  content: "\e90f";
}

.icon-15:before {
  content: "\e910";
}

.icon-16:before {
  content: "\e91b";
}

.icon-17:before {
  content: "\e914";
}

.icon-18:before {
  content: "\e915";
}

.icon-19:before {
  content: "\e9a7";
}

.icon-20:before {
  content: "\e911";
}

.icon-user1:before {
  content: "\e912";
}

.d-flex, .categorymenu-sidebar, .sa-full-list-outer, .sa-fpc-wrap .default-carousel-simple, .sa-footer-outer .upper-footer .downrow .contentdownload .btndownloadapp, .sa-footer-outer .upper-footer .downrow .down-phone-img, .sa-header-container .sa-menu ul, .sa-header-container .top-header .login-chat-cart .wishlist-cart ul, .sa-header-container .top-header .searchwrap .selectlaungage, .sa-header-container .top-header .searchwrap .search-control, .sa-header-container .top-header .searchwrap {
  display: flex;
}

.d-inline-flex, .categorymenu-sidebar li, .swopper-outer.description-outer .boxlike .tagslist li, .sa-countrymodal .trandinglist ul li, .sa-header-container .sa-menu ul li a:after, .sa-header-container .sa-menu ul li, .sa-header-container .top-header .login-chat-cart .wishlist-cart ul li, .sa-header-container .top-header .searchwrap .selectlaungage .selectinner span:before {
  display: inline-flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center, .categorymenu-sidebar li, .sa-header-container .sa-menu ul li, .sa-header-container .top-header .searchwrap .selectlaungage, .sa-header-container .top-header .searchwrap .search-control {
  align-items: center;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

html {
  font-size: 62.5%;
}
@media (max-height: 642px) {
  html {
    font-size: 55.5%;
  }
}

a:hover {
  color: #4c39ac;
  cursor: pointer;
}

.new-custom-switch {
  margin: 2.8rem 0px;
  position: relative;
  padding-left: 5.7rem;
  min-height: 3.9rem;
}
.new-custom-switch .custom-control-label {
  position: absolute;
  left: 0;
}
.new-custom-switch .custom-control-label:before {
  width: 4.3rem;
  height: 2.4rem;
  left: 0px;
  border-radius: 2.2rem;
  background: #adb5bd;
  box-shadow: none;
}
.new-custom-switch .custom-control-label:after {
  width: 1.2rem;
  height: 1.2rem;
  left: 0.9rem;
  top: 0.85rem;
  background: #fff;
  border-radius: 50%;
}
.new-custom-switch .custom-control-input:checked ~ .custom-control-label:before {
  background: #393939;
  border-color: #393939;
}
.new-custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  -webkit-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}
.new-custom-switch span {
  display: block;
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}
.new-custom-switch a {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.7rem;
  text-decoration: none;
}

.btn {
  height: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  outline: none;
}
.btn.btn-primary {
  background: #4C39AC;
  color: #ffffff;
}
.btn.btn-primary:active, .btn.btn-primary:focus {
  background: #4C39AC;
  box-shadow: none;
  outline: none;
}
.btn.btn-primary:not(:disabled):not(.disabled):active {
  background: #4C39AC;
  box-shadow: none;
  outline: none;
}
.btn.btn-border {
  border: 1px solid #4C39AC;
  background: #FFF;
  color: #535353;
}
.btn.btn-border:active, .btn.btn-border:focus {
  background: #ffffff;
  box-shadow: none;
  outline: none;
}
.btn.btn-border:not(:disabled):not(.disabled):active {
  background: #ffffff;
  box-shadow: none;
  outline: none;
}

body {
  font-size: 1.4rem;
  padding: 0;
  margin: 0;
  font-family: "Celias", sans-serif;
}
body.webversion {
  padding-top: 17.4rem;
}
@media (max-width: 991px) {
  body.webversion {
    padding-top: 11.5rem;
  }
}
body.hidden-scroll {
  overflow: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.form-group {
  width: 100%;
  margin: 0;
}
.form-group .form-control {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
  padding: 0 1.5rem;
}
.form-group .select2 {
  width: 100% !important;
}
.form-group .select2.select2-hidden-accessible {
  display: none;
}
.form-group .select2 .select2-selection {
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
}
.form-group .select2 .select2-selection .select2-selection__rendered {
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
.form-group .select2 .select2-selection .select2-selection__arrow {
  height: 4.8rem;
  width: 4rem;
}
.form-group .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
}
.form-group .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C2.17157 3.5621 2.82843 4.4379 4 6C4 6 5.82843 3.5621 7 2" stroke="black" stroke-miterlimit="3.99933" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 8px;
  height: 8px;
  position: absolute;
}
.form-group .mobile-input {
  position: relative;
}
.form-group .mobile-input .flag-box {
  width: auto;
  height: 5.2rem;
  border-radius: 1rem 0 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem 0 1.2rem;
  border: solid 0.1rem #e7e7e7;
  border-right: 0;
}
.form-group .mobile-input .flag-box .flagpt {
  max-width: 2rem;
}
.form-group .mobile-input .flag-box i {
  margin-left: 0.3rem;
  transform: rotate(90deg);
}
.form-group .mobile-input .form-control {
  border-radius: 0 1rem 1rem 0;
}

/* header css start */
.bgtwosection {
  background: rgb(248, 248, 248);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgb(248, 248, 248) 7%, rgb(255, 255, 255) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(248, 248, 248) 7%, rgb(255, 255, 255) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(248, 248, 248) 7%, rgb(255, 255, 255) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8f8", endColorstr="$white", GradientType=0);
  /* IE6-9 */
}

.sa-header-container {
  padding: 0rem;
  margin: 0rem 0 0rem;
  background: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: #ffffff;
  /* menu css*/
}
.sa-header-container .top-header {
  padding: 1.7rem 3rem;
  padding-right: 23.5rem;
  border-bottom: solid 0.1rem #eef2f6;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 8.4rem;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1;
}
@media (max-width: 991px) {
  .sa-header-container .top-header {
    height: 6rem;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
  }
}
.sa-header-container .top-header .menuicon {
  width: 4.5rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .menuicon {
    display: inline-flex;
  }
}
.sa-header-container .top-header .menuicon img {
  max-width: 1.6rem;
}
.sa-header-container .top-header .logo {
  outline: none;
  max-width: 10rem;
  flex-shrink: 0;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .logo {
    max-width: 8rem;
  }
}
.sa-header-container .top-header .logo img {
  max-width: 100%;
}
.sa-header-container .top-header .searchwrap {
  max-width: 141.7rem;
  width: 100%;
  margin: 0 1rem 0 2.5rem;
  background: #f9f9f9;
  border-radius: 1rem;
  border: solid 0.1rem #f0f0f0;
  position: relative;
  font-family: "Celias", sans-serif;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .searchwrap {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    height: 5.5rem;
  }
}
.sa-header-container .top-header .searchwrap form {
  width: 100%;
}
.sa-header-container .top-header .searchwrap .search-control {
  width: 100%;
  position: relative;
}
.sa-header-container .top-header .searchwrap .search-control .form-control {
  border: none;
  height: 5.2rem;
  outline: none;
  border-radius: 0;
  background: transparent;
  padding: 0 6rem 0 1.4rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  width: 100%;
  font-family: "Celias", sans-serif;
  box-shadow: none;
  outline: none;
}
.sa-header-container .top-header .searchwrap .search-control .btn {
  position: absolute;
  width: 7rem;
  height: 100%;
  background: transparent;
  right: 0;
  color: #1e1e1e;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: "Celias", sans-serif;
}
.sa-header-container .top-header .searchwrap .search-control .btn i {
  line-height: 0;
}
.sa-header-container .top-header .searchwrap .selectlaungage {
  background: #ffffff;
  border-radius: 0.3rem;
  min-width: 17.8rem;
  z-index: 1;
  border-radius: 1rem;
  height: 3.6rem;
  margin: 0rem;
  margin-left: 0.6rem;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .searchwrap .selectlaungage {
    z-index: 0;
  }
}
.sa-header-container .top-header .searchwrap .selectlaungage .selectinner {
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1.3rem;
  color: #000000;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: relative;
  font-family: "Celias", sans-serif;
}
.sa-header-container .top-header .searchwrap .selectlaungage .selectinner:after {
  content: "\e900";
  font-family: "swop";
  position: absolute;
  right: 0;
  width: 3.4rem;
  text-align: center;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  transform: rotate(90deg);
}
.sa-header-container .top-header .searchwrap .selectlaungage .selectinner span:before {
  content: "|";
  margin: 0 0.7rem;
  position: relative;
  font-size: 1.2rem;
}
.sa-header-container .top-header .searchwrap .selectlaungage .selectinner .flagicon {
  line-height: 0;
}
.sa-header-container .top-header .searchwrap .selectlaungage .selectinner .flagicon img {
  width: 2rem;
}
.sa-header-container .top-header .login-chat-cart {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart {
  padding: 0;
  margin: 0;
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart ul {
  padding: 0;
  margin: 0;
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart ul li {
  align-items: center;
  font-size: 1.4rem;
  margin: 0 1.5rem;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .login-chat-cart .wishlist-cart ul li {
    margin: 0 1rem;
  }
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart ul li a {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  position: relative;
  color: #CDCDCD;
  text-decoration: none;
  line-height: 0;
  font-family: "Celias", sans-serif;
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart ul li a i img {
  width: 2.2rem;
  height: 2.2rem;
}
.sa-header-container .top-header .login-chat-cart .wishlist-cart ul li a .count {
  font-size: 1rem;
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.1rem;
  background: #f64234;
  position: absolute;
  top: -1rem;
  right: -1rem;
  color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: "Celias", sans-serif;
}
.sa-header-container .top-header .signreg {
  padding: 0rem;
  padding-right: 3.2rem;
  margin: 0 0 0 2.5rem;
  position: relative;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .signreg {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .sa-header-container .top-header .signreg {
    margin-left: 1rem;
  }
}
.sa-header-container .top-header .signreg a {
  font-size: 1.3rem;
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Celias", sans-serif;
  font-family: "Celias", sans-serif;
}
.sa-header-container .top-header .signreg a i {
  font-size: 1.5rem;
  position: relative;
  margin-left: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  border-radius: 50%;
  color: #cccccc;
  border: solid 0.1rem #eef2f6;
}
.sa-header-container .top-header .signreg a span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 7.6rem;
  overflow: hidden;
  padding: 0rem 0;
  line-height: 5.62;
  letter-spacing: 0.03rem;
}
@media (max-width: 991px) {
  .sa-header-container .top-header .signreg a span.mobhidept {
    display: none;
  }
}
.sa-header-container .top-header .signreg a:hover {
  color: #4c39ac;
}
.sa-header-container .sellonbtn {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 23.5rem;
  height: 8.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5a638 url(../img/addprobg.png) 0 0 no-repeat;
  background-size: cover;
  z-index: 3;
}
@media (max-width: 991px) {
  .sa-header-container .sellonbtn {
    height: 100%;
    width: 5.2rem;
    position: fixed;
    height: 5.2rem;
    border-radius: 50%;
    bottom: 2rem;
    top: auto;
    right: 2rem;
  }
}
.sa-header-container .sellonbtn a {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: left;
  white-space: nowrap;
  text-decoration: none;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 500;
  z-index: 1;
  position: relative;
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-family: "Celias", sans-serif;
}
.sa-header-container .sellonbtn a .pluseico {
  width: 3.1rem;
  height: 3.1rem;
  margin-right: 1rem;
}
@media (max-width: 991px) {
  .sa-header-container .sellonbtn a .pluseico {
    margin-right: 0rem;
  }
}
@media (max-width: 991px) {
  .sa-header-container .sellonbtn a .mhide {
    display: none;
  }
}
@media (max-width: 991px) {
  .sa-header-container .sellonbtn .dropdown-web-menu {
    left: auto;
    right: 0.4rem;
    min-width: 19.5rem;
    top: auto;
    bottom: 100%;
    padding-top: 0rem;
    padding-bottom: 0.5rem;
  }
  .sa-header-container .sellonbtn .dropdown-web-menu ul::before, .sa-header-container .sellonbtn .dropdown-web-menu ul:after {
    display: none;
  }
}
.sa-header-container .sa-menu {
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  border-bottom: solid 0.1rem #eef2f6;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu {
    position: fixed;
    top: 0rem !important;
    height: 100%;
    z-index: 999999;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden !important;
    opacity: 0 !important;
  }
}
.sa-header-container .sa-menu .container {
  height: 100%;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu .container {
    max-width: 36.5rem;
    width: 100%;
    display: flex;
    flex-flow: column;
    margin: 0;
    background-color: #fff;
    left: -36.5rem;
    position: relative;
    transition: ease-in-out 0.3s;
  }
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu.open-it {
    visibility: visible !important;
    opacity: 1 !important;
  }
  .sa-header-container .sa-menu.open-it .container {
    left: 0;
  }
}
.sa-header-container .sa-menu .mob-top-model-header {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999;
  padding: 0 0rem;
  display: none;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu .mob-top-model-header {
    display: flex;
  }
}
.sa-header-container .sa-menu .mob-top-model-header h2 {
  font-size: 1.6rem;
  margin: 0;
}
.sa-header-container .sa-menu .mob-top-model-header .close_panel {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 3.8rem;
  justify-content: center;
  margin-left: auto;
  width: 3.8rem;
}
.sa-header-container .sa-menu ul {
  padding: 0;
  margin: 0;
  justify-content: center;
  height: 100%;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu ul {
    flex-flow: column;
    justify-content: flex-start;
    flex: 1 1 auto;
    overflow: auto;
    padding-bottom: 1.5rem;
    padding-top: 0.5em;
  }
}
.sa-header-container .sa-menu ul li {
  padding: 0;
  text-align: center;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu ul li + li {
    margin-top: 1rem;
  }
}
.sa-header-container .sa-menu ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-decoration: none;
  outline: none;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.99);
  padding: 0 3.7rem;
  font-family: "Celias", sans-serif;
  height: 100%;
  position: relative;
}
@media (max-width: 1199px) {
  .sa-header-container .sa-menu ul li a {
    padding: 0 2.3rem;
  }
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu ul li a {
    width: 100%;
    flex-flow: row;
    justify-content: flex-start;
    padding: 0 0rem;
  }
}
.sa-header-container .sa-menu ul li a:after {
  content: "";
  background: -moz-linear-gradient(top, rgb(238, 242, 246) 0%, rgb(244, 246, 249) 34%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(238, 242, 246) 0%, rgb(244, 246, 249) 34%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(238, 242, 246) 0%, rgb(244, 246, 249) 34%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$graylight", endColorstr="#00ffffff", GradientType=0);
  /* IE6-9 */
  position: absolute;
  right: 0;
  top: 0;
  width: 0.1rem;
  height: 100%;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu ul li a:after {
    display: none;
  }
}
.sa-header-container .sa-menu ul li a .iconpt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: #eef2f6;
  margin-bottom: 0.8rem;
  border-radius: 1.4rem;
  overflow: hidden;
  font-size: 2.1rem;
  color: #9cafbc;
}
@media (max-width: 991px) {
  .sa-header-container .sa-menu ul li a .iconpt {
    margin-bottom: 0rem;
    margin-right: 0.8rem;
  }
}
.sa-header-container .sa-menu ul li a .iconpt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sa-header-container .sa-menu ul li a.moreicons .iconpt img {
  object-fit: contain;
  width: auto;
  height: auto;
  width: 1.5rem;
  height: 1.5rem;
}
.sa-header-container .sa-menu ul li a:hover {
  color: #4c39ac;
}
.sa-header-container .sa-menu ul li:last-child a:after {
  display: none;
}

/* header css end */
/* footer css start */
.sa-footer-outer {
  position: relative;
}
.sa-footer-outer .upper-footer {
  position: absolute;
  width: 100%;
  top: -21rem;
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer {
    position: static;
  }
}
.sa-footer-outer .upper-footer .downrow {
  background-color: #493c8e;
  background-image: url(../img/footappbg.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 38rem;
  padding: 6rem;
  border-radius: 0.8rem;
  position: relative;
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer .downrow {
    padding: 2rem;
    height: 28rem;
  }
}
.sa-footer-outer .upper-footer .downrow .down-phone-img {
  position: relative;
  width: 40%;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer .downrow .down-phone-img {
    display: none;
  }
}
.sa-footer-outer .upper-footer .downrow .down-phone-img img {
  max-width: 100%;
}
.sa-footer-outer .upper-footer .downrow .contentdownload {
  width: 60%;
  padding: 0 6% 0;
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer .downrow .contentdownload {
    width: 100%;
    padding: 0;
  }
}
.sa-footer-outer .upper-footer .downrow .contentdownload h2 {
  font-size: 3rem;
  font-weight: normal;
  margin: 0 0 2rem;
  line-height: 4.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.95);
  font-family: "Celias", sans-serif;
}
@media (max-width: 991px) {
  .sa-footer-outer .upper-footer .downrow .contentdownload h2 {
    font-size: 2.6rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer .downrow .contentdownload h2 {
    font-size: 2rem;
  }
}
.sa-footer-outer .upper-footer .downrow .contentdownload h2 b {
  font-weight: 500;
}
.sa-footer-outer .upper-footer .downrow .contentdownload p {
  font-size: 1.7rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.77);
  margin: 0;
}
@media (max-width: 767px) {
  .sa-footer-outer .upper-footer .downrow .contentdownload p {
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
.sa-footer-outer .upper-footer .downrow .contentdownload .btndownloadapp {
  margin: 3.5rem -1.2rem 0;
}
@media (max-width: 991px) {
  .sa-footer-outer .upper-footer .downrow .contentdownload .btndownloadapp {
    margin-top: 2.5rem;
  }
}
.sa-footer-outer .upper-footer .downrow .contentdownload .btndownloadapp .btn {
  padding: 0 1.2rem;
  margin: 0;
  outline: none;
  box-shadow: none;
}
.sa-footer-outer .upper-footer .downrow .contentdownload .btndownloadapp .btn img {
  max-width: 100%;
}
.sa-footer-outer .bottom-footer {
  padding: 9rem 0 8rem;
  border-top: 0.1rem solid #eef2f6;
  background-color: #F9F9F9;
}
.sa-footer-outer .bottom-footer .footer-logo {
  padding: 0;
  margin: 0;
}
@media (max-width: 767px) {
  .sa-footer-outer .bottom-footer .footer-logo {
    margin: 0 auto 1.5rem;
    text-align: center;
  }
}
.sa-footer-outer .bottom-footer .footer-logo span {
  font-size: 1.2rem;
  display: block;
  padding-top: 2rem;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Celias", sans-serif;
}
.sa-footer-outer .bottom-footer .footer-logo img {
  width: 80%;
}
@media (max-width: 767px) {
  .sa-footer-outer .bottom-footer .footer-logo img {
    max-width: 180px;
  }
}
.sa-footer-outer .bottom-footer .menu-list {
  padding: 0;
  margin: 0;
}
.sa-footer-outer .bottom-footer .menu-list h3 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.56;
  color: #000000;
}
@media (max-width: 767px) {
  .sa-footer-outer .bottom-footer .menu-list h3 {
    font-size: 1.4rem;
  }
}
.sa-footer-outer .bottom-footer .menu-list ul {
  padding: 0;
  margin: 0;
}
.sa-footer-outer .bottom-footer .menu-list ul li {
  list-style: none;
  padding: 0.6rem 0;
}
.sa-footer-outer .bottom-footer .menu-list ul li a {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.97);
  font-weight: normal;
  letter-spacing: 0.023rem;
  font-family: "Celias", sans-serif;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sa-footer-outer .bottom-footer .menu-list ul li a {
    font-size: 1.3rem;
  }
}
.sa-footer-outer .bottom-footer .menu-list ul li a:hover {
  color: #4c39ac;
}
.sa-footer-outer .bottom-footer .legal-point {
  margin-top: 5rem;
}
.sa-footer-outer .bottom-footer .socila-icon {
  padding: 0;
  margin: 0;
}
@media (max-width: 991px) {
  .sa-footer-outer .bottom-footer .socila-icon {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767px) {
  .sa-footer-outer .bottom-footer .socila-icon {
    margin-top: 1.5rem;
  }
}
.sa-footer-outer .bottom-footer .socila-icon ul {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 -0.7rem;
}
@media (max-width: 991px) {
  .sa-footer-outer .bottom-footer .socila-icon ul {
    margin: 0;
    justify-content: center;
  }
}
.sa-footer-outer .bottom-footer .socila-icon ul li {
  display: inline-block;
  margin: 0 0.7rem;
}
.sa-footer-outer .bottom-footer .socila-icon ul li a {
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background-color: #e8eef6;
  font-size: 1.8rem;
  color: #3164d8;
  display: block;
  text-decoration: none;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-footer-outer .bottom-footer .socila-icon ul li a:hover {
  background-color: #d7e1ef;
}
.sa-footer-outer .poweredby-bar {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.7rem 1rem;
}
.sa-footer-outer .poweredby-bar p {
  margin: 0;
  color: #9f9f9f;
  letter-spacing: 0.02rem;
  font-size: 1.3rem;
  font-weight: normal;
}
.sa-footer-outer.sa-home-footer {
  margin-top: 29.2rem;
}
@media (max-width: 767px) {
  .sa-footer-outer.sa-home-footer {
    margin: 0rem;
  }
}
.sa-footer-outer.sa-home-footer .bottom-footer {
  padding-top: 26.5rem;
}
@media (max-width: 991px) {
  .sa-footer-outer.sa-home-footer .bottom-footer {
    padding-top: 23.5rem;
    padding-bottom: 5rem;
  }
}
@media (max-width: 767px) {
  .sa-footer-outer.sa-home-footer .bottom-footer {
    padding-bottom: 0;
    margin-top: 4rem;
    padding-top: 2rem;
  }
}

/* footer css end */
/*home css start */
.sa-home-banner {
  padding-top: 3.5rem;
  position: relative;
}
@media (max-width: 991px) {
  .sa-home-banner {
    padding-top: 1.5rem;
  }
}
.sa-home-banner .sasecouter {
  border-radius: 1.8rem;
  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}
.sa-home-banner .sa-banner-outer {
  position: relative;
  border-radius: 1.8rem 1.8rem 0rem 0rem;
  overflow: hidden;
}
.sa-home-banner .sa-home-slider {
  border-radius: 0rem 0rem 1.8rem 1.8rem;
  overflow: hidden;
}
.sa-home-banner .home-item {
  position: relative;
  width: 100%;
}
.sa-home-banner .home-item img {
  width: 100%;
}
.sa-home-banner .home-item .slider-content {
  position: absolute;
  right: 20%;
  top: 25%;
  text-align: center;
  color: #ffffff;
}
.sa-home-banner .home-item .slider-content h1 {
  font-weight: 500;
  opacity: 0.9;
  font-size: 3rem;
  margin-bottom: 2.2rem;
}
.sa-home-banner .home-item .slider-content p {
  text-transform: uppercase;
  font-size: 1.3rem;
  opacity: 0.8;
  font-weight: 500;
}
.sa-home-banner .slider_arrow {
  position: absolute;
  bottom: 2.6rem;
  right: 2.3rem;
}
@media (max-width: 991px) {
  .sa-home-banner .slider_arrow {
    bottom: 1rem;
    right: 1rem;
  }
}
.sa-home-banner .slider_arrow ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.sa-home-banner .slider_arrow ul li {
  list-style: none;
  margin: 0 0.7rem;
}
.sa-home-banner .slider_arrow ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7d7d7d;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  padding: 0;
  transition: 0.3s;
}
.sa-home-banner .slider_arrow ul li a img {
  width: 1.4rem;
  height: 1.4rem;
}
.sa-home-banner .slider_arrow ul li:hover a {
  transform: scale(1.5);
}

.sa-secure-wrap {
  padding: 0;
  margin: 0;
  position: relative;
}
.sa-secure-wrap ul {
  padding: 0;
  margin: 0;
  width: 100%;
}
.sa-secure-wrap ul li {
  padding: 3.1rem 2.6rem;
  list-style: none;
}
@media (max-width: 991px) {
  .sa-secure-wrap ul li {
    padding: 1rem 0rem;
  }
}
@media (max-width: 767px) {
  .sa-secure-wrap ul li {
    width: 50%;
  }
}
.sa-secure-wrap ul li:after {
  content: "";
  background: #eef2f6;
  background: -webkit-gradient(linear, left top, left bottom, from(#eef2f6), to(#ffffff));
  background: linear-gradient(to bottom, #eef2f6 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$black", GradientType=0);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.1rem;
  height: 100%;
}
.sa-secure-wrap ul li a {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  color: #050505;
  padding: 0 1.5rem;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sa-secure-wrap ul li a {
    flex-flow: column;
    padding: 0;
  }
}
.sa-secure-wrap ul li a .iconimg {
  margin-right: 2rem;
}
@media (max-width: 991px) {
  .sa-secure-wrap ul li a .iconimg {
    margin: 0 0 1rem;
  }
}
.sa-secure-wrap ul li a .securecontent {
  font-size: 1.4rem;
  color: #050505;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sa-secure-wrap ul li a .securecontent {
    text-align: center;
    padding: 0 1rem;
  }
}
@media (max-width: 767px) {
  .sa-secure-wrap ul li a .securecontent {
    font-size: 1.3rem;
  }
}
.sa-secure-wrap ul li a .securecontent .securehead {
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
@media (max-width: 991px) {
  .sa-secure-wrap ul li a .securecontent .securehead {
    font-size: 1.2rem;
  }
}
.sa-secure-wrap ul li a .securecontent span {
  display: block;
  color: rgba(0, 0, 0, 0.77);
  font-size: 1.3rem;
  letter-spacing: 0.08rem;
}
.sa-secure-wrap ul li a .securecontent span .secure-inner {
  margin-bottom: 0;
  padding-top: 0.3rem;
  font-size: 1.02rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}
.sa-secure-wrap .filterbywrap {
  display: flex;
  align-items: center;
  padding: 2.4rem 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1199px) {
  .sa-secure-wrap .filterbywrap {
    flex-flow: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .sa-secure-wrap .filterbywrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1199px) {
  .sa-secure-wrap .filterbywrap.resetallbtns {
    position: absolute;
    top: 2.3rem;
    right: 3rem;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .sa-secure-wrap .filterbywrap.resetallbtns {
    right: 1.5rem;
  }
}
.sa-secure-wrap .filterbywrap h3 {
  display: inline-flex;
  flex-shrink: 0;
  margin: 0 2.2rem 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.03rem;
  color: #000000;
}
@media (max-width: 1199px) {
  .sa-secure-wrap .filterbywrap h3 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
    padding-right: 8rem;
  }
}
@media (max-width: 767px) {
  .sa-secure-wrap .filterbywrap h3 {
    padding-right: 6.5rem;
  }
}
.sa-secure-wrap .filterbywrap ul {
  display: flex;
  align-items: center;
  width: auto;
}
@media (max-width: 1199px) {
  .sa-secure-wrap .filterbywrap ul {
    width: 100%;
    padding-right: 0;
    margin: 0 0 1.5rem;
  }
}
.sa-secure-wrap .filterbywrap ul li {
  display: inline-flex;
  width: 18.3rem;
  padding: 0 1rem;
}
.sa-secure-wrap .filterbywrap ul li .form-group {
  width: 100%;
  margin: 0;
}
.sa-secure-wrap .filterbywrap ul li .form-group .form-control {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
  padding: 0 1.5rem;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 {
  width: 100% !important;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2.select2-hidden-accessible {
  display: none;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 .select2-selection {
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 .select2-selection .select2-selection__rendered {
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 .select2-selection .select2-selection__arrow {
  height: 4.8rem;
  width: 4rem;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
}
.sa-secure-wrap .filterbywrap ul li .form-group .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C2.17157 3.5621 2.82843 4.4379 4 6C4 6 5.82843 3.5621 7 2" stroke="black" stroke-miterlimit="3.99933" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 8px;
  height: 8px;
  position: absolute;
}

.sa-comman-section {
  padding: 4rem 0 4rem;
}
@media (max-width: 991px) {
  .sa-comman-section {
    padding: 3rem 0 3rem;
  }
}
.sa-comman-section .slick-prev,
.sa-comman-section .slick-next {
  width: 4.6rem;
  height: 4.6rem;
  box-shadow: 0 0 8.1rem 0 rgba(0, 0, 0, 0.29);
  z-index: 4;
  background-color: #ffffff;
  border-radius: 50%;
}
.sa-comman-section .slick-prev::before,
.sa-comman-section .slick-next::before {
  content: "\e900";
  font-family: "swop";
  color: #000000;
}
.sa-comman-section .slick-prev {
  left: -2.4rem;
  transform: translate(0, -50%) rotate(-180deg);
}
@media (max-width: 1366px) {
  .sa-comman-section .slick-prev {
    left: -1.4rem;
  }
}
.sa-comman-section .slick-next {
  right: -2.4rem;
}
@media (max-width: 1366px) {
  .sa-comman-section .slick-next {
    right: -1.4rem;
  }
}

.sa-heading-section {
  position: relative;
  display: flex;
  padding: 0 0rem;
  margin: 0rem 0 3rem;
}
@media (max-width: 991px) {
  .sa-heading-section {
    margin: 0rem 0 1.6rem;
  }
}
.sa-heading-section .sa-title {
  color: rgba(0, 0, 0, 0.99);
  font-size: 2.6rem;
  font-weight: normal;
  font-family: "Celias", sans-serif;
  margin: 0;
}
@media (max-width: 991px) {
  .sa-heading-section .sa-title {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .sa-heading-section .sa-title {
    font-size: 1.6rem;
    align-items: center;
    display: flex;
  }
}
.sa-heading-section .sa-title span {
  font-weight: 500;
}
.sa-heading-section .sa-view {
  margin-left: auto;
  line-height: 2;
  color: #144db4;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sa-heading-section .sa-view {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .sa-heading-section .sa-view {
    font-size: 1.2rem;
  }
}
.sa-heading-section .sa-view i {
  margin-left: 0.3rem;
  font-size: 115%;
}
.sa-heading-section .sa-view i img {
  max-width: 100%;
}
.sa-heading-section .sa-view:hover {
  color: #4c39ac;
}
.sa-heading-section .subdescription {
  font-size: 1.5rem;
  text-align: left;
  color: #8c8c8c;
  display: block;
  width: 100%;
  margin-top: 0.5rem;
}

.sa-fpc-wrap .default-carousel-simple {
  padding: 0;
  margin: 0 -1.5rem;
}
@media (max-width: 991px) {
  .sa-fpc-wrap .default-carousel-simple {
    margin: 0 -1rem;
  }
}
@media (max-width: 767px) {
  .sa-fpc-wrap .default-carousel-simple {
    overflow: auto;
  }
}
.sa-fpc-wrap .default-carousel-simple .item {
  width: 20%;
  padding: 0 1.5rem;
}
@media (max-width: 991px) {
  .sa-fpc-wrap .default-carousel-simple .item {
    padding: 0 1rem;
  }
}
@media (max-width: 767px) {
  .sa-fpc-wrap .default-carousel-simple .item {
    width: 50%;
    min-width: 15.2rem;
  }
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box {
  width: 100%;
  margin: 0;
  margin-bottom: 0;
  position: relative;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .favourite-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  padding: 0;
  font-size: 1.6rem;
  outline: none;
  box-shadow: none;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .favourite-icon svg {
  width: 2.2rem;
  height: 2.2rem;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .favourite-icon.active svg path {
  fill: #ffffff;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box a {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-image {
  border: 0.1rem solid #eef2f6;
  border-radius: 1.8rem;
  text-align: center;
  background-color: #001753;
  height: 30rem;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-image {
    height: 22.5rem;
  }
}
@media (max-width: 991px) {
  .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-image {
    height: 15.2rem;
  }
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content {
  padding: 2rem 1.2rem;
}
@media (max-width: 991px) {
  .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content {
    padding: 1rem 1.2rem;
  }
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-title {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.03rem;
  color: #000000;
  margin-bottom: 1rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  height: 4.8rem;
}
@media (max-width: 767px) {
  .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-title {
    font-size: 1.3rem;
    height: 3.9rem;
  }
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-location {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #969696;
  font-size: 1.3rem;
}
@media (max-width: 767px) {
  .sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-location {
    font-size: 1.1rem;
  }
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-location .location {
  margin-right: 0.5rem;
  font-size: 1.3rem;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box .ad-content .ad-location .chat {
  padding: 0;
  float: right;
  margin-left: auto;
  font-size: 2.6rem;
  color: #0d0d0d;
  margin-top: -0.6rem;
}
.sa-fpc-wrap .default-carousel-simple .item .ad-box:hover .ad-content .ad-title {
  color: #4c39ac;
}

.sa-add-banner {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}
@media (max-width: 991px) {
  .sa-add-banner {
    padding: 2rem 0;
  }
}
.sa-add-banner .sa-inside-add {
  padding: 0;
  margin: 0;
}
.sa-add-banner .sa-inside-add img {
  max-width: 100%;
}

.sa-tranding-cate {
  padding-bottom: 1rem;
}
.sa-tranding-cate .sa-trending-card {
  margin-bottom: 0;
}
.sa-tranding-cate .sa-trending-card li {
  list-style: none;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .sa-tranding-cate .sa-trending-card li {
    width: 50%;
  }
}
.sa-tranding-cate .sa-trending-card li a {
  padding: 2.1rem 2.2rem;
  border-radius: 2rem;
  box-shadow: 0.15rem 0.26rem 0.6rem 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
}
@media (max-width: 991px) {
  .sa-tranding-cate .sa-trending-card li a {
    flex-flow: column;
    padding: 1.5rem;
  }
}
.sa-tranding-cate .sa-trending-card li a .iconimg {
  width: 8rem;
  height: 8rem;
  border-radius: 1.4rem;
  background-color: #8c8c8c;
  margin-right: 2.4rem;
  flex-shrink: 0;
  overflow: hidden;
}
@media (max-width: 991px) {
  .sa-tranding-cate .sa-trending-card li a .iconimg {
    margin: 0 0 1.5rem;
  }
}
.sa-tranding-cate .sa-trending-card li a .securecontent {
  font-family: "Celias", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.99);
}
@media (max-width: 991px) {
  .sa-tranding-cate .sa-trending-card li a .securecontent {
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .sa-tranding-cate .sa-trending-card li a .securecontent {
    font-size: 1.3rem;
  }
}
.sa-tranding-cate .sa-trending-card li a .securecontent .securehead {
  display: block;
}
.sa-tranding-cate .sa-trending-card li a .securecontent .securetext {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.02rem;
  color: rgba(0, 0, 0, 0.7);
  display: block;
}
@media (max-width: 767px) {
  .sa-tranding-cate .sa-trending-card li a .securecontent .securetext {
    font-size: 1.2rem;
  }
}
.sa-tranding-cate .sa-trending-card li a .go-icon {
  margin-left: auto;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
  color: #000000;
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 991px) {
  .sa-tranding-cate .sa-trending-card li a .go-icon {
    display: none;
  }
}
.sa-tranding-cate .sa-trending-card li a .go-icon i {
  margin-left: -2rem;
}
.sa-tranding-cate .sa-trending-card li:hover a {
  box-shadow: 0rem 0rem 1.6rem rgba(0, 0, 0, 0.11);
}
.sa-tranding-cate .sa-trending-card li:hover a .securehead {
  color: #4c39ac;
}
.sa-tranding-cate .sa-trending-card li:hover a .go-icon {
  visibility: visible;
  opacity: 1;
}
.sa-tranding-cate .sa-trending-card li:hover a .go-icon i {
  margin-left: 0rem;
}

.sa-about-slider {
  padding: 0;
  margin: 0;
  position: relative;
}
.sa-about-slider:before, .sa-about-slider:after {
  content: "";
  height: 100%;
  background-color: #ffffff;
  width: 1.5rem;
  position: absolute;
  z-index: 1;
  top: 0;
}
.sa-about-slider:before {
  left: -1.5rem;
}
.sa-about-slider:after {
  right: -1.5rem;
}
.sa-about-slider .slick-list {
  margin: 0 -1.5rem;
}
.sa-about-slider .slick-list .slick-track {
  min-width: 100vw;
}
.sa-about-slider li {
  list-style: none;
}
.sa-about-slider li .about-item-inner {
  width: 100%;
  padding: 0 1.5rem;
}
.sa-about-slider li .about-item-inner img {
  width: 100%;
  border-radius: 1.8rem;
}

.shadowpt {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.55);
  visibility: hidden;
}

.sa-countrymodal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999999;
  padding: 1.5rem;
  display: none;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.sa-countrymodal .sa-modal-innerwrap {
  width: 100%;
  max-width: 125rem;
  z-index: 1;
  margin: auto;
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content {
  background: #fbfbfb;
  background-size: cover;
  padding: 9.4rem 2rem 7.3rem;
  border-radius: 1.8rem;
  border: none;
  position: relative;
}
@media (max-width: 991px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content {
    padding: 7.4rem 1.5rem 4rem;
  }
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .close-modal {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 10px;
  position: absolute;
  font-size: 16px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .logo-modal {
  text-align: center;
  max-width: 14.6rem;
  margin: 0 auto 3.8rem;
}
@media (max-width: 991px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .logo-modal {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .logo-modal {
    margin-bottom: 2rem;
  }
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .logo-modal img {
  max-width: 100%;
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap {
  text-align: center;
  max-width: 52rem;
  width: 100%;
  margin: 0 auto 3.1rem;
}
@media (max-width: 767px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap {
    margin: 0 auto 2rem;
  }
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap h2 {
  font-size: 2.3rem;
  font-weight: 500;
  color: #000;
  margin: 0 0 1.1rem;
  letter-spacing: 0.02rem;
}
@media (max-width: 991px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap h2 {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap h2 {
    font-size: 1.6rem;
    margin: 0 0 0.8rem;
  }
}
.sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap p {
  font-size: 1.5rem;
  font-weight: normal;
  color: #8c8c8c;
  margin: 0;
}
@media (max-width: 991px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap p {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .sa-modal-innerwrap .sa-modal-content .modal-contentwrap p {
    font-size: 1.3rem;
  }
}
.sa-countrymodal.open-it {
  display: flex;
}
.sa-countrymodal.open-it .shadowpt {
  visibility: visible;
}
.sa-countrymodal .location-group {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 85.2rem;
  width: 100%;
  margin: auto;
  border: solid 0.1rem #e5e5e4;
  border-radius: 1.4rem;
  position: relative;
  box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.04);
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group {
    flex-flow: column;
  }
}
.sa-countrymodal .location-group .listrowchange:after {
  content: "";
  height: 49px;
  position: absolute;
  right: -0.5rem;
  width: 0.1rem;
  background: #e6e6e6;
  display: block;
  top: 0.9rem;
  z-index: 1;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .listrowchange:after {
    width: 100%;
    height: 0.1rem;
    left: 0;
    top: 100%;
  }
}
.sa-countrymodal .location-group .listrowchange {
  padding: 0 1rem 0 2.3rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: normal;
  color: #474747;
  position: relative;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  height: 6.6rem;
  border-radius: 1.4rem 0 0 1.4rem;
  font-family: "Celias", sans-serif;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .listrowchange {
    width: 100%;
    height: 4.5rem;
    border-radius: 1.4rem 1.4rem 0 0;
  }
}
.sa-countrymodal .location-group .listrowchange span {
  font-size: 1.8rem;
  color: #000000;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.sa-countrymodal .location-group .listrowchange span i {
  transform: rotate(90deg);
}
.sa-countrymodal .location-group .listrowchange span .flagmob {
  width: 2.7rem;
  margin-right: 1rem;
}
.sa-countrymodal .location-group .form-box {
  width: 65%;
  position: relative;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .form-box {
    width: 100%;
  }
}
.sa-countrymodal .location-group .form-box:before {
  content: "\e906";
  position: absolute;
  left: 2rem;
  font-size: 1.8rem;
  font-family: "swop" !important;
  color: #b5b5b5;
  top: 2rem;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .form-box:before {
    top: 1rem;
  }
}
.sa-countrymodal .location-group .form-box .form-control {
  height: 6.6rem;
  border: none;
  padding: 0 0 0 5rem;
  border-radius: 0 0 1.4rem 1.4rem;
  box-shadow: none;
  outline: none;
  color: #474747;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .form-box .form-control {
    height: 4.5rem;
  }
}
.sa-countrymodal .location-group .locationinner {
  position: absolute;
  right: 0.7rem;
  font-size: 1.3rem;
  color: #474747;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 5.2rem;
  padding: 0 2rem;
  background: #f5f5f5;
  text-decoration: none;
  border-radius: 1.1rem;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .locationinner {
    width: 3.7rem;
    height: 3.7rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0.4rem;
    right: 0.4rem;
  }
}
.sa-countrymodal .location-group .locationinner i {
  font-size: 1.9rem;
  margin-right: 1.5rem;
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .locationinner i {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .location-group .locationinner .txtpt {
    display: none;
  }
}
.sa-countrymodal .location-group .locationinner:hover {
  background-color: #eef2f6;
}
.sa-countrymodal .trandinglist {
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 9.6rem;
}
@media (max-width: 991px) {
  .sa-countrymodal .trandinglist {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .trandinglist {
    margin-top: 2rem;
  }
}
.sa-countrymodal .trandinglist h3 {
  font-size: 1.7rem;
  color: #000000;
  font-weight: 500;
  margin: 0 0 2.1rem;
  font-family: "Celias", sans-serif;
  letter-spacing: 0.026rem;
}
@media (max-width: 991px) {
  .sa-countrymodal .trandinglist h3 {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767px) {
  .sa-countrymodal .trandinglist h3 {
    margin-bottom: 0.8rem;
  }
}
.sa-countrymodal .trandinglist ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.sa-countrymodal .trandinglist ul li {
  padding: 0.7rem 0.75rem;
  list-style: none;
}
@media (max-width: 767px) {
  .sa-countrymodal .trandinglist ul li {
    padding: 0.5rem 0.5rem;
  }
}
.sa-countrymodal .trandinglist ul li a {
  min-height: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.7rem;
  outline: none;
  text-decoration: none;
  border: solid 0.1rem #e7e7e7;
  border-radius: 1rem;
  font-size: 1.2rem;
  color: #8c8c8c;
  background: #ffffff;
}
@media (max-width: 767px) {
  .sa-countrymodal .trandinglist ul li a {
    min-height: 3.2rem;
    padding: 1rem 1.2rem;
  }
}
.sa-countrymodal .trandinglist ul li.active a {
  border-color: #4c39ac;
  background: #4c39ac;
  color: #ffffff;
}

/* listing page css */
.sa-search-list-outer .sa-comman-section {
  padding-bottom: 2.2rem;
}
.sa-search-list-outer .sa-fpc-wrap .default-carousel-simple .item .ad-box {
  margin-bottom: 1.8rem;
}
.sa-search-list-outer .sa-fpc-wrap .default-carousel-simple .item.advertisement .ad-box {
  height: 100%;
  display: flex;
  width: 100%;
}
.sa-search-list-outer .sa-fpc-wrap .default-carousel-simple .item.advertisement .ad-box a {
  width: 100%;
  height: 100%;
}
.sa-search-list-outer .sa-fpc-wrap .default-carousel-simple .item.advertisement .ad-box .ad-image {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  background: transparent;
  border: none;
}
.sa-search-list-outer .sa-fpc-wrap .default-carousel-simple .item.advertisement .ad-box .ad-image img {
  object-fit: contain;
  justify-content: flex-start;
  display: flex;
  margin-bottom: auto;
  height: auto;
}

.sa-filter-list {
  margin-left: auto;
}
@media (max-width: 1199px) {
  .sa-filter-list {
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }
}
@media (max-width: 767px) {
  .sa-filter-list {
    flex-flow: column;
    padding: 0;
  }
}

.sa-map-radio {
  text-align: right;
  display: flex;
  align-items: center;
}
.sa-map-radio + .sa-map-radio {
  margin-left: 1.5rem;
}
@media (max-width: 767px) {
  .sa-map-radio + .sa-map-radio {
    margin: 0;
    margin-top: 1.5rem;
  }
}
.sa-map-radio .flash-label {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.03rem;
  color: #000000;
  font-family: "Celias", sans-serif;
  margin-right: 1.6rem;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sa-map-radio .flash-label {
    margin-right: 1rem;
    font-size: 1.3rem;
    min-width: 7rem;
  }
}
.sa-map-radio .switch {
  position: relative;
  display: inline-flex;
  width: 5.7rem;
  height: 2.8rem;
  flex-shrink: 0;
  margin: 0;
}
.sa-map-radio .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sa-map-radio .switch input:focus + .sa-slider {
  box-shadow: none;
}
.sa-map-radio .switch input:checked + .sa-slider:before {
  -webkit-transform: translateX(2.6rem);
  -ms-transform: translateX(2.6rem);
  transform: translateX(2.6rem);
  border: 0.7rem solid #144db4;
  background-color: #ffffff;
}
.sa-map-radio .switch .sa-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sa-map-radio .switch .sa-slider:before {
  position: absolute;
  content: "";
  height: 2.2rem;
  width: 2.2rem;
  left: 0.3rem;
  bottom: 0.3rem;
  background-color: #ffffff;
  border: 0.7rem solid #bebebe;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sa-map-radio .switch .sa-slider.round {
  border-radius: 34px;
}
.sa-map-radio .switch .sa-slider.round:before {
  border-radius: 50%;
}

/* Rounded sliders */
.sa-pagination {
  display: flex;
  justify-content: center;
  margin: 0 0 5rem;
}
.sa-pagination a {
  color: black;
  float: left;
  height: 4.5rem;
  width: 4.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  margin: 0 0.6rem;
  border-radius: 1.4rem;
  text-decoration: none;
  border: 1px solid #e5e5e5;
  background-color: #f6f6f6;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.sa-pagination a.active {
  background-color: #144db4;
  border-color: #144db4;
  color: #ffffff;
}
.sa-pagination a:hover:not(.active) {
  background-color: #ddd;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
}

.sa-full-list-outer {
  margin: 0 -1.5rem;
}
.sa-full-list-outer .default-carousel-simple {
  margin: 0;
}
@media (max-width: 767px) {
  .sa-full-list-outer.sa-map-list-outer {
    flex-flow: column;
    flex-flow: column-reverse;
  }
}
.sa-full-list-outer.sa-map-list-outer .default-carousel-simple {
  width: 60%;
  margin: 0;
}
@media (max-width: 767px) {
  .sa-full-list-outer.sa-map-list-outer .default-carousel-simple {
    width: 100%;
  }
}
.sa-full-list-outer.sa-map-list-outer .default-carousel-simple .item {
  width: 33.33%;
}
@media (max-width: 767px) {
  .sa-full-list-outer.sa-map-list-outer .default-carousel-simple .item {
    width: 50%;
  }
}
.sa-full-list-outer.sa-map-list-outer .sa-map-part {
  width: 40%;
  padding: 0 1.5rem;
}
@media (max-width: 767px) {
  .sa-full-list-outer.sa-map-list-outer .sa-map-part {
    width: 100%;
  }
}
.sa-full-list-outer.sa-map-list-outer .sa-map-part img {
  border-radius: 1.8rem;
  width: 100%;
  height: 82.6rem;
  object-fit: cover;
}

.comman-side-popup {
  max-width: 36.5rem;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  padding: 0px;
  right: -479px;
}
.comman-side-popup .top-logo-title {
  margin: 0.6rem 0 2.7rem;
}
.comman-side-popup .side-model-logo {
  padding: 0px;
  margin: 0 0 1.4rem;
}
.comman-side-popup .side-model-logo img {
  max-width: 13rem;
  height: auto;
}
.comman-side-popup .comman-popup-inner {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  top: 0;
  background: #FFF;
  z-index: 1;
  overflow: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
  display: flex;
  flex-flow: column;
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #f1f3f4;
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar-track {
  background: #f1f3f4;
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar-thumb {
  background: rgba(15, 20, 37, 0.22);
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar-thumb:hover {
  background: rgba(15, 20, 37, 0.4);
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar-thumb:vertical {
  background: rgba(15, 20, 37, 0.22);
  border-radius: 10px;
}
.comman-side-popup .comman-popup-inner::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(15, 20, 37, 0.33);
}
.comman-side-popup .comman-popup-inner .popup-model-body {
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0px;
  overflow: auto;
  padding: 2rem;
}
.comman-side-popup .comman-popup-inner .row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.comman-side-popup .comman-popup-inner .row [class*=col-] {
  padding-left: 1rem;
  padding-right: 1rem;
}
.comman-side-popup .comman-popup-inner h2 {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.comman-side-popup .comman-popup-inner h2 span {
  display: block;
  font-size: 1.5rem;
  color: #8a8a8a;
  margin: 0 0 0;
  font-weight: normal;
}
.comman-side-popup .comman-popup-inner h2 span a {
  color: #ed1b23;
  outline: none;
  font-family: "Celias", sans-serif;
}
.comman-side-popup .comman-popup-inner .form-group {
  width: 100%;
  margin: 0;
  margin-bottom: 1.6rem;
}
.comman-side-popup .comman-popup-inner .form-group label {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 0 1rem;
  width: 100%;
}
.comman-side-popup .comman-popup-inner .form-group label span {
  color: #CECECE;
}
.comman-side-popup .comman-popup-inner .form-group label .infowrap {
  margin-left: 0.5rem;
  cursor: pointer;
}
.comman-side-popup .comman-popup-inner .form-group .form-control {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
  padding: 0 1.5rem;
}
.comman-side-popup .comman-popup-inner .form-group textarea.form-control {
  height: auto;
  padding: 1.1rem 1.5rem;
}
.comman-side-popup .comman-popup-inner .form-group .the-count-time {
  padding: 0;
  margin: 0.2rem 0 0;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #bfc1c5;
}
.comman-side-popup .comman-popup-inner .form-group .select2 {
  width: 100% !important;
}
.comman-side-popup .comman-popup-inner .form-group .select2.select2-hidden-accessible {
  display: none;
}
.comman-side-popup .comman-popup-inner .form-group .select2 .select2-selection {
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
}
.comman-side-popup .comman-popup-inner .form-group .select2 .select2-selection .select2-selection__rendered {
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
.comman-side-popup .comman-popup-inner .form-group .select2 .select2-selection .select2-selection__arrow {
  height: 4.8rem;
  width: 4rem;
}
.comman-side-popup .comman-popup-inner .form-group .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
}
.comman-side-popup .comman-popup-inner .form-group .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C2.17157 3.5621 2.82843 4.4379 4 6C4 6 5.82843 3.5621 7 2" stroke="black" stroke-miterlimit="3.99933" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 8px;
  height: 8px;
  position: absolute;
}
.comman-side-popup .comman-popup-inner .form-group .form-control {
  height: 5.2rem;
  color: #000000;
}
.comman-side-popup .comman-popup-inner .form-group .passwordpart {
  position: relative;
}
.comman-side-popup .comman-popup-inner .form-group.forgot_pass_group {
  position: relative;
}
.comman-side-popup .comman-popup-inner .form-group.forgot_pass_group .form-control {
  padding-right: 8rem;
}
.comman-side-popup .comman-popup-inner .form-group.forgot_pass_group .forgot_pass {
  position: absolute;
  right: 1.5rem;
  color: #EB4227;
  font-size: 1.2rem;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  height: 5.2rem;
  top: 0;
  display: flex;
  align-items: center;
}
.comman-side-popup .comman-popup-inner .form-group .mobile-input .form-control {
  border-radius: 0 1rem 1rem 0;
}
.comman-side-popup .comman-popup-inner .form-group .remarkswrap {
  padding: 0;
  margin: 0;
}
.comman-side-popup .comman-popup-inner .form-group .remarkswrap li {
  list-style: none;
}
.comman-side-popup .comman-popup-inner .form-group .remarkswrap li + li {
  margin-left: 3rem;
}
.comman-side-popup .comman-popup-inner .form-group .remarkswrap li .radio-outer label {
  color: #000000;
  margin-bottom: 0rem;
}
.comman-side-popup .comman-popup-inner .btn_row {
  margin-top: 0.4rem;
  margin-bottom: 3.8rem;
}
.comman-side-popup .comman-popup-inner .btn_row .btn {
  width: 100%;
}
.comman-side-popup .comman-popup-inner .btn_row .btn.btn-secondary {
  background: #1e1e1e;
  color: #fff;
  border-color: #1e1e1e;
}
.comman-side-popup .register-web {
  text-align: center;
  margin: auto;
  display: block;
  font-size: 1.5rem;
  color: #6D6D6D;
  margin: 0 0 1.6rem;
}
.comman-side-popup .register-web a {
  text-transform: uppercase;
  color: #4c39ac;
  margin-left: 2px;
  font-weight: 500;
  text-decoration: none;
}
.comman-side-popup .web-social-icon {
  text-align: center;
  margin: 5.7rem 0 0 0;
  display: inline-block;
  width: 100%;
}
.comman-side-popup .web-social-icon .continue {
  color: #959595;
  font-family: "Celias", sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 2rem;
  display: flex;
  justify-content: center;
}
.comman-side-popup .web-social-icon ul {
  padding: 0;
  margin: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comman-side-popup .web-social-icon ul li {
  display: inline-flex;
  padding: 0 0.85rem;
}
.comman-side-popup .web-social-icon ul li a {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
}
.comman-side-popup .web-social-icon ul li a img {
  max-width: 2.2rem;
}
.comman-side-popup .web-social-icon ul li a.fbicon {
  background-color: #3b5999;
}
.comman-side-popup .web-social-icon ul li a.gpicon {
  background-color: #db4d41;
}
.comman-side-popup .web-social-icon ul li a.appleico {
  background-color: #151515;
}
.comman-side-popup .shadowpt {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.55);
  visibility: hidden;
  opacity: 0;
}
.comman-side-popup.open-it {
  right: 0;
}
.comman-side-popup.open-it .shadowpt {
  opacity: 1;
  visibility: visible;
}
.comman-side-popup .forgotimgpt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0 2rem;
}
.comman-side-popup .close_panel {
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;
}
.comman-side-popup .close_panel:hover {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
}
.comman-side-popup .backarrow {
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-right: auto;
}
.comman-side-popup .backarrow:hover {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
}
.comman-side-popup .top-model-header {
  min-height: 6.8rem;
  height: 7.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999;
  padding: 0 2rem;
}
.comman-side-popup .model-footer-panel {
  display: flex;
  width: 100%;
  padding: 1rem 2rem 1rem;
  align-items: flex-end;
  border-top: 1px solid #EBEBEB;
}
.comman-side-popup .model-footer-panel .btn {
  padding: 0 4rem;
}
.comman-side-popup.lg-side-popup {
  max-width: 67.2rem;
  right: -67.8rem;
}
.comman-side-popup.lg-side-popup.open-it {
  right: 0;
}
.comman-side-popup .dp-img {
  position: relative;
  margin: 0 auto 4rem;
  width: 8.2rem;
  height: 8.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comman-side-popup .dp-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.comman-side-popup .dp-img .img-icon {
  position: absolute;
  background: #545454;
  font-size: 1.2rem;
  left: 5.7rem;
  color: #fff;
  width: 3rem;
  height: 3rem;
  right: auto;
  bottom: 0.5rem;
  right: -0.4rem;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.comman-side-popup .dp-img .img-icon img {
  max-width: 100%;
  height: auto;
  width: auto;
}
.comman-side-popup .dp-img .img-icon input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.comman-side-popup .switch-btns-row {
  margin-top: 2.4rem;
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .comman-side-popup .switch-btns-row {
    flex-flow: column;
    margin-top: 0;
  }
}
.comman-side-popup .switch-btns-row .new-custom-switch {
  margin: 0;
}
@media (max-width: 767px) {
  .comman-side-popup .switch-btns-row .new-custom-switch {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.comman-side-popup .switch-btns-row .btn {
  display: flex;
  width: 30.6rem;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.comman-side-popup .alreadybar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  border-top: solid 0.1rem #EBEBEB;
}
@media (max-width: 767px) {
  .comman-side-popup .alreadybar {
    padding: 1.5rem 0 0;
  }
}
.comman-side-popup .alreadybar p {
  margin: 0;
  color: #6D6D6D;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
.comman-side-popup .alreadybar p a {
  margin-left: 1rem;
  text-decoration: none;
  color: #4C39AC;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.category-list {
  display: flex;
  padding: 0;
  margin: 0;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
}
.category-list li {
  list-style: none;
  display: inline-flex;
  padding: 0rem;
  width: 25%;
}
@media (max-width: 767px) {
  .category-list li {
    width: 33.33%;
  }
}
.category-list li .category-image {
  margin-bottom: 0.4rem;
  border: 0.2rem solid transparent;
  padding: 0.4rem;
}
.category-list li .category-image img {
  border-radius: 1rem;
  max-width: 100%;
}
.category-list li.active .category-image {
  border-radius: 1.2rem;
  border-color: #4C39AC;
}
.category-list.productuploded li {
  padding: 0 0.6rem;
}

.steps-panel-top {
  position: relative;
  margin-bottom: 3.3rem;
}
.steps-panel-top:before {
  content: "";
  max-width: 70%;
  background: #eee;
  position: absolute;
  top: 1.7rem;
  left: 50%;
  height: 0.1rem;
  z-index: 1;
  width: 100%;
  transform: translateX(-50%);
}
.steps-panel-top .progressbar {
  max-width: 70%;
  position: absolute;
  top: 1.7rem;
  left: 9.6rem;
  height: 0.1rem;
  z-index: 1;
  background: #000000;
}
@media (max-width: 467px) {
  .steps-panel-top .progressbar {
    left: 6.6rem;
  }
}
.steps-panel-top ul {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  position: relative;
}
.steps-panel-top ul li {
  display: inline-flex;
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps-panel-top ul li .commanstep {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .steps-panel-top ul li .commanstep {
    text-align: center;
    font-size: 1rem;
  }
}
.steps-panel-top ul li .commanstep .cricle {
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 50%;
  border: 0.1rem solid #000;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #959595;
  font-family: "Celias", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
  position: relative;
  z-index: 1;
}
.steps-panel-top ul li .commanstep .valuecheckpt {
  visibility: hidden;
  min-height: 2rem;
}
.steps-panel-top ul li .commanstep.done .valuecheckpt {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  display: flex;
  visibility: visible;
}
@media (max-width: 767px) {
  .steps-panel-top ul li .commanstep.done .valuecheckpt {
    font-size: 1rem;
  }
}
.steps-panel-top ul li .commanstep.done .cricle {
  background-color: #F64234;
  border-color: #F64234;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11' viewBox='0 0 16 11' fill='none'%3E%3Cpath d='M0.857178 5.5001L5.6548 9.92868L15.25 1.07153' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0px;
}

.comman-data {
  display: none;
}
.comman-data.active {
  display: flex;
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload__btn {
  cursor: pointer;
  width: 100%;
  height: 14.47rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  background: #8972C6;
}
@media (max-width: 767px) {
  .upload__btn {
    height: 11.2rem;
  }
}
.upload__btn:hover {
  background: #7b62c0;
  transition: all 0.3s ease;
}
.upload__btn-box {
  width: 100%;
}
.upload__img-box {
  width: 100%;
  padding: 0 0;
  margin-bottom: 1.2rem;
  height: 14.47rem;
  position: relative;
  border-radius: 0.6rem;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .upload__img-box {
    height: 11.2rem;
  }
}
.upload__img-close {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 0.74rem;
  right: 0.74rem;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload__img-close:after {
  content: "";
  background-image: url(../img/close.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}

.postdecriptions {
  width: 100%;
}

.congrulation-model {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  display: none;
  padding: 1.5rem;
}
.congrulation-model .close_panel {
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.congrulation-model .close_panel:hover {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
}
.congrulation-model .comman-popup-inner {
  max-width: 56.8rem;
  border-radius: 1.2rem;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: #fff;
  z-index: 1;
}
.congrulation-model .congrulation-top {
  border-radius: 1.2rem 1.2rem 0rem 0rem;
  background: #43329A;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.congrulation-model .congrulation-top img {
  max-width: 100%;
}
.congrulation-model .congrulations-body {
  padding: 2.2rem 1.5rem 4.2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .congrulation-model .congrulations-body {
    padding-bottom: 2.2rem;
  }
}
.congrulation-model .congrulations-body .title-congrate {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 2.3rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.2rem;
}
.congrulation-model .congrulations-body p {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.5;
  font-weight: normal;
  margin: 0;
}
.congrulation-model .congrulations-body p + p {
  margin-top: 0.6rem;
}
.congrulation-model .congrate-foot {
  padding: 0rem 1.5rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .congrulation-model .congrate-foot {
    padding: 0rem 1rem 2.2rem;
  }
}
.congrulation-model .congrate-foot .btn {
  display: inline-flex;
  width: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}
@media (max-width: 767px) {
  .congrulation-model .congrate-foot .btn {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    text-transform: none;
  }
}
.congrulation-model .congrate-foot .btn + .btn {
  margin-left: 2rem;
}
@media (max-width: 767px) {
  .congrulation-model .congrate-foot .btn + .btn {
    margin-left: 1rem;
  }
}
.congrulation-model.open-it {
  display: flex;
}
.congrulation-model.open-it .shadowpt {
  visibility: visible;
  opacity: 1;
}

.dropdown-parents {
  position: relative;
}
.dropdown-parents .dropdown-web-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  padding-top: 0.9rem;
  display: none;
}
.dropdown-parents .dropdown-web-menu ul {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.4rem 1.4rem 0rem rgba(0, 0, 0, 0.06);
  width: 100%;
  position: relative;
}
.dropdown-parents .dropdown-web-menu ul:after, .dropdown-parents .dropdown-web-menu ul:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.dropdown-parents .dropdown-web-menu ul:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 0.8rem;
  margin-left: -0.8rem;
}
.dropdown-parents .dropdown-web-menu ul:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 0.9rem;
  margin-left: -0.9rem;
}
.dropdown-parents .dropdown-web-menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-parents .dropdown-web-menu ul li + li {
  border-top: solid 0.1rem #E4E4E4;
}
.dropdown-parents .dropdown-web-menu ul li a {
  display: flex;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  padding: 2rem 3.6rem 2rem 2rem;
  align-items: center;
  border-left: solid 0.2rem transparent;
}
.dropdown-parents .dropdown-web-menu ul li a i {
  flex-shrink: 0;
  width: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.2rem;
}
.dropdown-parents .dropdown-web-menu ul li a i img {
  max-width: 100%;
}
.dropdown-parents .dropdown-web-menu ul li:hover a {
  border-left-color: #4C39AC;
  color: #000000;
}
.dropdown-parents:hover .dropdown-web-menu {
  display: block;
}
.dropdown-parents .dropdown-sm-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.4rem 1.4rem 0rem rgba(0, 0, 0, 0.06);
  min-width: 11.5rem;
  right: 0;
  z-index: 1;
}
.dropdown-parents .dropdown-sm-menu ul {
  padding: 0.4rem;
  margin: 0;
}
.dropdown-parents .dropdown-sm-menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  border: none;
}
.dropdown-parents .dropdown-sm-menu ul li a {
  display: flex;
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.6rem 1rem;
  text-decoration: none;
}
.dropdown-parents .dropdown-sm-menu ul li.sa-delete:hover a,
.dropdown-parents .dropdown-sm-menu ul li.sa-delete a {
  color: #F64234;
}
.dropdown-parents .dropdown-sm-menu ul li:hover a {
  color: #000000;
}
.dropdown-parents.active .dropdown-sm-menu {
  display: block;
}

.innerpages {
  background-color: #f8f8f8;
}

.sa-inner-pages-wrap {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  background-color: #F8F8F8;
}
@media (max-width: 991px) {
  .sa-inner-pages-wrap {
    padding-top: 2.5rem;
  }
}

.sa-profile-wrap {
  padding: 0;
  margin: 0;
}

.sa-list-address-wrap {
  padding: 0;
  margin: 0;
  border-radius: 1.8rem;
  background: #ffffff;
  padding: 2.6rem 0 1.7rem;
}
@media (max-width: 991px) {
  .sa-list-address-wrap {
    margin-bottom: 1.5rem;
  }
}
.sa-list-address-wrap .profiledp {
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1.5rem;
}
.sa-list-address-wrap .profiledp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.sa-list-address-wrap .sa--media-body {
  padding: 0;
  margin: 0;
  text-align: center;
  padding-bottom: 2rem;
}
.sa-list-address-wrap .sa--media-body h2 {
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 0.6rem;
}
.sa-list-address-wrap .sa--media-body .sa-locwrap {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 0 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-list-address-wrap .sa--media-body .sa-locwrap i {
  margin-right: 0.2rem;
}
.sa-list-address-wrap .sa--media-body .sa-socile {
  padding: 0;
  margin: 0;
}
.sa-list-address-wrap .sa--media-body .sa-socile ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-list-address-wrap .sa--media-body .sa-socile ul li {
  display: inline-flex;
  list-style: none;
}
.sa-list-address-wrap .sa--media-body .sa-socile ul li + li {
  margin-left: 1.2rem;
}

.sa-follow-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 0.1rem solid #EBEBEB;
  border-bottom: 0.1rem solid #EBEBEB;
}
.sa-follow-row .sa-folowers-col {
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1.1rem;
}
.sa-follow-row .sa-folowers-col + .sa-folowers-col {
  border-left: 0.1rem solid #EBEBEB;
}
.sa-follow-row .sa-folowers-col .sa-follow-no {
  color: #000;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 0.2rem;
  display: block;
  width: 100%;
}
.sa-follow-row .sa-folowers-col .sa-follow-text {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
  text-align: center;
  width: 100%;
}

.sa-aside-menu {
  margin-top: 1rem;
}
@media (max-width: 991px) {
  .sa-aside-menu {
    display: flex;
  }
}
.sa-aside-menu ul {
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}
@media (max-width: 991px) {
  .sa-aside-menu ul {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    overflow: auto;
  }
}
.sa-aside-menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 991px) {
  .sa-aside-menu ul li {
    white-space: nowrap;
  }
}
.sa-aside-menu ul li + li {
  border-top: solid 0.1rem #E4E4E4;
}
.sa-aside-menu ul li a {
  display: flex;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-weight: 400;
  letter-spacing: 0.03rem;
  padding: 1.6rem 2rem;
  padding-right: 4rem;
  align-items: center;
  border-left: solid 0.2rem transparent;
  text-decoration: none;
  position: relative;
  font-size: 1.4rem;
}
@media (max-width: 991px) {
  .sa-aside-menu ul li a {
    border-left: none;
    border-top: solid 0.2rem transparent;
  }
}
.sa-aside-menu ul li a:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'%3E%3Cpath d='M1 1L7 7L1 13' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  font-family: "swop";
  position: absolute;
  right: 1.4rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 1.6rem;
  width: 2.4rem;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 991px) {
  .sa-aside-menu ul li a:after {
    transform: rotate(90deg);
  }
}
.sa-aside-menu ul li a i {
  flex-shrink: 0;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.2rem;
}
.sa-aside-menu ul li a i img {
  max-width: 100%;
  opacity: 0.8;
}
.sa-aside-menu ul li a span {
  line-height: 0;
}
.sa-aside-menu ul li.active > a, .sa-aside-menu ul li:hover > a {
  border-left-color: #4C39AC;
  color: #000000;
  background: linear-gradient(90deg, rgba(76, 57, 172, 0.04) 0%, rgba(76, 57, 172, 0) 100%);
}
@media (max-width: 991px) {
  .sa-aside-menu ul li.active > a, .sa-aside-menu ul li:hover > a {
    border-left: transparent;
    border-top-color: #4C39AC;
  }
}
.sa-aside-menu ul li.active > a i img, .sa-aside-menu ul li:hover > a i img {
  opacity: 1;
}
.sa-aside-menu ul li.dropdown-aside .dropdown-web-menu {
  display: none;
}
.sa-aside-menu ul li.dropdown-aside .dropdown-web-menu ul li a {
  display: inline-block;
  width: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.sa-aside-menu ul li.dropdown-aside .dropdown-web-menu ul li a:after {
  top: 0;
}
.sa-aside-menu ul li.dropdown-aside .dropdown-web-menu ul li + li {
  border-top: 0px;
}
.sa-aside-menu ul li.dropdown-aside.active > a:after {
  transform: rotate(90deg);
}
.sa-aside-menu ul li.dropdown-aside.active .dropdown-web-menu {
  display: block;
}

.sa-inner-right-wrap {
  border-radius: 1.8rem;
  background: #FFF;
  padding: 1rem 2rem 2rem;
  min-height: 100%;
}
@media (max-width: 767px) {
  .sa-inner-right-wrap .sa-top-heading {
    flex-flow: column;
  }
}
@media (max-width: 767px) {
  .sa-inner-right-wrap .sa-top-heading .sa-heading-tab {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sa-inner-right-wrap .sa-top-heading .sa-heading-tab ul li a {
    padding: 0.8rem 1.2rem;
    font-size: 1.2rem;
  }
}
.sa-inner-right-wrap.chatboxpanel {
  display: flex;
  flex-flow: column;
  padding: 1rem 0 0;
  overflow: hidden;
}
.sa-inner-right-wrap.chatboxpanel .sa-top-heading {
  margin: 0;
  padding: 0 2rem;
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 2rem;
  background-color: #F4F4F4;
  box-sizing: border-box;
  max-height: 52.9rem;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #f1f3f4;
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar-track {
  background: #f1f3f4;
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar-thumb {
  background: rgba(15, 20, 37, 0.22);
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar-thumb:hover {
  background: rgba(15, 20, 37, 0.4);
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar-thumb:vertical {
  background: rgba(15, 20, 37, 0.22);
  border-radius: 10px;
}
.sa-inner-right-wrap.chatboxpanel .sa-inner-body::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(15, 20, 37, 0.33);
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter {
  padding: 0;
  margin: 0;
  position: relative;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .attachment,
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .sendbutton {
  position: absolute;
  overflow: hidden;
  outline: none;
  border: none;
  background-color: #fff;
  top: auto;
  bottom: 0;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .attachment {
  left: 0;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .attachment input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .sendbutton {
  right: 0;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Celias", sans-serif;
  padding: 1.6rem 6.1rem;
  height: 5.5rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.1rem;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #f1f3f4;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar-track {
  background: #f1f3f4;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar-thumb {
  background: rgba(15, 20, 37, 0.22);
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar-thumb:hover {
  background: rgba(15, 20, 37, 0.4);
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar-thumb:vertical {
  background: rgba(15, 20, 37, 0.22);
  border-radius: 10px;
}
.sa-inner-right-wrap.chatboxpanel .chatbotmsgfooter .form-control::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(15, 20, 37, 0.33);
}

.sa-top-heading {
  padding: 0;
  width: 100%;
  border-bottom: 0.1rem solid #EBEBEB;
  margin: 0 0 2.6rem;
  display: flex;
  align-items: center;
}
.sa-top-heading h2 {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  margin: 0;
  margin-right: auto;
  padding: 1.2rem 0;
}
.sa-top-heading .sa-heading-tab {
  padding: 0;
  margin: 0;
  margin-left: auto;
}
.sa-top-heading .sa-heading-tab ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.sa-top-heading .sa-heading-tab ul li {
  display: inline-flex;
  list-style: none;
}
.sa-top-heading .sa-heading-tab ul li a {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-decoration: none;
  padding: 1.5rem;
  position: relative;
}
.sa-top-heading .sa-heading-tab ul li a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1rem;
  background-color: transparent;
  bottom: -0.1rem;
  left: 0;
}
.sa-top-heading .sa-heading-tab ul li a:hover {
  color: #000000;
}
.sa-top-heading .sa-heading-tab ul li.active a {
  color: #000000;
}
.sa-top-heading .sa-heading-tab ul li.active a:after {
  background-color: #4C39AC;
}

.sa-inner-body .dp-img {
  position: relative;
  margin: 0 auto 4rem;
  width: 8.2rem;
  height: 8.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-inner-body .dp-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.sa-inner-body .dp-img .img-icon {
  position: absolute;
  background: #545454;
  font-size: 1.2rem;
  left: 5.7rem;
  color: #fff;
  width: 3rem;
  height: 3rem;
  right: auto;
  bottom: 0.5rem;
  right: -0.4rem;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.sa-inner-body .dp-img .img-icon img {
  max-width: 100%;
  height: auto;
  width: auto;
}
.sa-inner-body .dp-img .img-icon input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.sa-inner-body .form-group {
  width: 100%;
  margin: 0;
  margin-bottom: 1.6rem;
}
.sa-inner-body .form-group label {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 0 1rem;
  width: 100%;
}
.sa-inner-body .form-group label span {
  color: #CECECE;
}
.sa-inner-body .form-group label .infowrap {
  margin-left: 0.5rem;
  cursor: pointer;
}
.sa-inner-body .form-group .form-control {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
  padding: 0 1.5rem;
}
.sa-inner-body .form-group textarea.form-control {
  height: auto;
  padding: 1.1rem 1.5rem;
}
.sa-inner-body .form-group .the-count-time {
  padding: 0;
  margin: 0.2rem 0 0;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #bfc1c5;
}
.sa-inner-body .form-group .select2 {
  width: 100% !important;
}
.sa-inner-body .form-group .select2.select2-hidden-accessible {
  display: none;
}
.sa-inner-body .form-group .select2 .select2-selection {
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
}
.sa-inner-body .form-group .select2 .select2-selection .select2-selection__rendered {
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
.sa-inner-body .form-group .select2 .select2-selection .select2-selection__arrow {
  height: 4.8rem;
  width: 4rem;
}
.sa-inner-body .form-group .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
}
.sa-inner-body .form-group .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C2.17157 3.5621 2.82843 4.4379 4 6C4 6 5.82843 3.5621 7 2" stroke="black" stroke-miterlimit="3.99933" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 8px;
  height: 8px;
  position: absolute;
}
.sa-inner-body .form-group .form-control {
  height: 5.2rem;
  color: #000000;
}
.sa-inner-body .form-group .passwordpart {
  position: relative;
}
.sa-inner-body .form-group.forgot_pass_group {
  position: relative;
}
.sa-inner-body .form-group.forgot_pass_group .form-control {
  padding-right: 8rem;
}
.sa-inner-body .form-group.forgot_pass_group .forgot_pass {
  position: absolute;
  right: 1.5rem;
  color: #EB4227;
  font-size: 1.2rem;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  height: 5.2rem;
  top: 0;
  display: flex;
  align-items: center;
}
.sa-inner-body .form-group .mobile-input .form-control {
  border-radius: 0 1rem 1rem 0;
}
.sa-inner-body .form-group .remarkswrap {
  padding: 0;
  margin: 0;
}
.sa-inner-body .form-group .remarkswrap li {
  list-style: none;
}
.sa-inner-body .form-group .remarkswrap li + li {
  margin-left: 3rem;
}
.sa-inner-body .form-group .remarkswrap li .radio-outer label {
  color: #000000;
  margin-bottom: 0rem;
}
.sa-inner-body .row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.sa-inner-body .row [class*=col-] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sa-button-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 2rem;
}
@media (max-width: 767px) {
  .sa-button-bar {
    padding: 1.5rem 0 0.5rem;
  }
}
.sa-button-bar .btn {
  text-decoration: none;
  display: inline-flex;
  width: auto;
  padding: 0 4rem;
}
@media (max-width: 767px) {
  .sa-button-bar .btn {
    padding: 0 1rem;
    text-transform: none;
    font-size: 1.3rem;
  }
}
.sa-button-bar .btn + .btn {
  margin-left: 2rem;
}
@media (max-width: 767px) {
  .sa-button-bar .btn + .btn {
    padding: 0 0.8rem;
  }
}

.sa-no-recoard {
  padding: 0;
  margin: 0;
  padding: 13rem 0 10rem;
}
.sa-no-recoard__imgbox {
  padding: 0;
  margin: 0 0 2.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-no-recoard__imgbox img {
  max-width: 100%;
}
.sa-no-recoard__data {
  padding: 0;
}
.sa-no-recoard__data h2 {
  color: #000;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 0.7rem;
}
.sa-no-recoard__data p {
  color: #848484;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.sa-list-all-cards .row {
  margin-left: -0.9rem;
  margin-right: -0.9rem;
}
.sa-list-all-cards .row [class*=col-] {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.sa-list-box {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0rem 0.1rem 0.6rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0 0 1rem 1rem;
  margin-bottom: 1.8rem;
}
.sa-list-box__top {
  top: 0;
  right: 0;
  width: 3rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 0 0.8rem 0 0.8rem;
  color: #000000;
  position: absolute;
  cursor: pointer;
}
.sa-list-box__image {
  width: 100%;
  height: 16rem;
  border-radius: 1rem 1rem 0rem 0rem;
  overflow: hidden;
}
.sa-list-box__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sa-list-box__content {
  padding: 1.2rem 1.6rem;
}
.sa-list-box__content__title {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .sa-list-box__content__title {
    font-size: 1.3rem;
  }
}

.sa-card-main {
  padding: 1rem;
  margin: 0rem 0 1.8rem;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0rem 0.1rem 0.6rem 0rem rgba(0, 0, 0, 0.1);
  min-height: 23.2rem;
  display: flex;
  flex-flow: column;
}
.sa-card-main .flex-head {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  color: #848484;
  margin: 0 0 1rem;
}
.sa-card-main .flex-container {
  margin: 0 -0.25rem;
  display: flex;
  position: relative;
}
.sa-card-main .flex-container .flex-img {
  width: 50%;
  padding: 0 0.25rem;
}
.sa-card-main .flex-container .flex-img img {
  width: 100%;
  height: 10.3rem;
  object-fit: cover;
}
.sa-card-main .flex-container .flex-img p {
  font-size: 1.4rem;
  color: #000;
  padding: 0.6rem 0;
  text-align: left;
  margin: 0;
  letter-spacing: 0.03rem;
  line-height: 2.25rem;
}
.sa-card-main .flex-container .set-left {
  text-align: left;
}
.sa-card-main .flex-container .set-left img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid #9E9E9E;
}
.sa-card-main .flex-container .set-right {
  text-align: right;
}
.sa-card-main .flex-container .set-right img {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid #9E9E9E;
}
.sa-card-main .flex-container .compare_icon {
  width: 3.3rem;
  height: 3.3rem;
  position: absolute;
  top: 3.6rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-card-main .flex-container .compare_icon img {
  border: 0;
}
.sa-card-main .flex-bottom {
  margin-top: auto;
}
.sa-card-main .flex-bottom .btn {
  border-radius: 0.6rem;
  border-color: #535353;
  background: #FFF;
  height: 2.6rem;
  color: #535353;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  align-items: center;
  display: flex;
}
.sa-card-main .flex-bottom .btn i {
  margin-right: 0.4rem;
}
.sa-card-main .flex-bottom .btn i img {
  height: 1.7rem;
  width: auto;
}
.sa-card-main .flex-bottom .btn:hover {
  border-color: #4c39ac;
}
.sa-card-main .flex-bottom .sa-rating_star {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.sa-card-main .flex-bottom .sa-rating_star li {
  list-style: none;
}
.sa-card-main .flex-bottom .sa-rating_star li + li {
  margin-left: 0.3rem;
}
.sa-card-main .flex-bottom .sa-rating_star li span {
  font-size: 1.6rem;
  color: #c5c5c5;
}
.sa-card-main .flex-bottom .sa-rating_star li.active span {
  color: #FFBA51;
}
.sa-card-main .flex-bottom .sa-rating-by {
  margin-left: auto;
  color: #848484;
  text-align: right;
  font-family: "Celias", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  display: flex;
  align-items: center;
}
.sa-card-main .flex-bottom .sa-rating-by b {
  font-weight: 500;
}
.sa-card-main.card-bg-foot {
  padding-bottom: 0;
}
.sa-card-main.card-bg-foot .flex-bottom {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.7rem 1rem;
  border-radius: 0rem 0rem 1rem 1rem;
  background: #F4F4F4;
}

.chatting-list-outer {
  padding: 0;
  margin: 0;
  width: 100%;
}
.chatting-list-outer ul {
  padding: 0;
  margin: 0;
}
.chatting-list-outer ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #EBEBEB;
}
.chatting-list-outer ul li:first-child .chatlistrow {
  padding-top: 0;
}

.chatlistrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 1.2rem 0;
  position: relative;
  padding-right: 4.6rem;
}
@media (max-width: 767px) {
  .chatlistrow {
    flex-flow: column;
    padding-right: 0;
  }
}
.chatlistrow .chatting-left {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 767px) {
  .chatlistrow .chatting-left {
    align-items: flex-start;
    width: 100%;
  }
}
.chatlistrow .chatting-left .chatimg {
  display: inline-flex;
  flex-shrink: 0;
  width: 5.3rem;
  height: 5.3rem;
  position: relative;
  margin-right: 2.7rem;
}
@media (max-width: 767px) {
  .chatlistrow .chatting-left .chatimg {
    margin-right: 1.7rem;
  }
}
.chatlistrow .chatting-left .chatimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.chatlistrow .chatting-left .chatimg .chatuserimg {
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: -0.8rem;
  bottom: 0;
}
.chatlistrow .chatting-left .chatimg .chatuserimg img {
  border: solid 0.2rem #ffffff;
}
.chatlistrow .chatting-left .chatcontent {
  padding: 0;
  margin: 0;
}
.chatlistrow .chatting-left .chatcontent .chattitle {
  padding: 0;
  margin: 0 0 0.2rem;
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .chatlistrow .chatting-left .chatcontent .chattitle {
    padding-right: 3.5rem;
  }
}
.chatlistrow .chatting-left .chatcontent .chattitle .chatcount {
  display: inline-flex;
  flex-shrink: 0;
  margin-left: 1rem;
  width: 2.1rem;
  height: 2.1rem;
  background: #F64234;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
}
.chatlistrow .chatting-left .chatcontent .chattitle a {
  color: #000000;
  text-decoration: none;
}
.chatlistrow .chatting-left .chatcontent p {
  margin: 0;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.25rem;
  /* 173.077% */
  letter-spacing: 0.03rem;
}
.chatlistrow .chatting-right {
  margin-left: auto;
  text-align: right;
}
.chatlistrow .chatting-right .datetimept {
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
}
.chatlistrow .chatting-right .chatname {
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
}
.chatlistrow .chatmenuspt {
  position: absolute;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  cursor: pointer;
  top: auto;
  bottom: auto;
  padding: 1rem 0;
  visibility: hidden;
  opacity: 0;
}
.chatlistrow .chatmenuspt .dropdown-sm-menu {
  min-width: 12.5rem;
}
.chatlistrow:hover .chatmenuspt {
  visibility: visible;
  opacity: 1;
}

.chat-history-panel {
  padding: 0;
  margin: 0;
  padding: 2.8rem 0;
}
.chat-history-panel ul {
  padding: 0;
  margin: 0;
}
.chat-history-panel ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 0.6rem;
}
.chat-history-panel ul li .chatothermsg {
  border-radius: 1.5rem 1.5rem 1.5rem 0rem;
  border: 0.1rem solid #EBEBEB;
  background: #FFF;
  padding: 1.2rem 1.4rem;
  max-width: 80%;
  width: auto;
  display: inline-flex;
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  margin: 0 0 0.8rem;
}
.chat-history-panel ul li .chatmsgdatetime {
  color: rgba(0, 0, 0, 0.4);
  font-family: "Celias", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.chat-history-panel ul li.rightside {
  text-align: right;
}
.chat-history-panel ul li.rightside .chatothermsg {
  border-radius: 1.5rem 1.5rem 0rem 1.5rem;
  background: rgba(33, 204, 242, 0.24);
  border: none;
}

.addsigneaturebar {
  padding: 0 5rem;
}
@media (max-width: 767px) {
  .addsigneaturebar {
    padding: 0 1.5rem;
  }
}
.addsigneaturebar .chatlistrow {
  padding-right: 0;
}
.addsigneaturebar .chatlistrow .chatting-left {
  max-width: 24.8rem;
  width: 100%;
  margin-right: auto;
}
@media (max-width: 767px) {
  .addsigneaturebar .chatlistrow .chatting-left {
    max-width: 100%;
  }
}
.addsigneaturebar .chatlistrow .chatting-left .chatimg {
  margin-right: 1.7rem;
}
.addsigneaturebar .chatlistrow .compare_icon {
  margin: auto;
  top: auto;
  bottom: auto;
}
@media (max-width: 767px) {
  .addsigneaturebar .chatlistrow .compare_icon {
    transform: rotate(90deg);
    margin-bottom: 1.5rem;
  }
}
.addsigneaturebar .chatlistrow .chatting-right {
  max-width: 32.2rem;
  width: 100%;
}
@media (max-width: 767px) {
  .addsigneaturebar .chatlistrow .chatting-right {
    max-width: 100%;
  }
}
.addsigneaturebar .chatlistrow .chatting-right .addsuggest {
  border: 1px dashed #DEDEDE;
  background: #FCFCFC;
  display: flex;
  padding: 0.8rem 6rem;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.addsigneaturebar .chatlistrow .chatting-right .addsuggest input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.addsigneaturebar .chatlistrow .chatting-right .addsuggest span {
  color: #000;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
}

.backflow {
  display: flex;
  align-items: center;
  width: 100%;
}
.backflow .backbtnhead {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 50%;
  font-size: 1.8rem;
  color: #000;
}
.backflow .chatimg {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
.backflow .chatimg img {
  display: inline-flex;
  flex-shrink: 0;
  width: 2.8rem;
  height: 2.8rem;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}
.backflow .chatmenuspt {
  padding: 1rem;
  z-index: 1;
}
.backflow .chatmenuspt .dropdown-sm-menu {
  width: 12.5rem;
}

.sa-refferal-cards {
  padding-bottom: 4rem;
}
.sa-refferal-cards .refferalimg {
  padding: 0;
  margin: 0;
  margin: 0 -2rem;
}
.sa-refferal-cards .refferalimg img {
  width: 100%;
}
.sa-refferal-cards .refferal-content {
  max-width: 61.1rem;
  margin: auto;
  text-align: center;
}
.sa-refferal-cards .refferal-content .title {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 2.3rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}
.sa-refferal-cards .refferal-content p {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
.sa-refferal-cards .refferal-content .referralcode {
  text-align: center;
  margin-bottom: 3.8rem;
}
.sa-refferal-cards .refferal-content .referralcode .ref_code {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
.sa-refferal-cards .refferal-content .referralcode .ref_code span {
  display: block;
  color: #4C39AC;
  font-family: "Celias", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 1.2rem;
}
.sa-refferal-cards .refferal-content .btn {
  width: auto;
  padding: 0 4rem;
  display: inline-flex;
}

.sa-trems-pages-wrap {
  padding-top: 2rem;
}
.sa-trems-pages-wrap .breadcrumpart {
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
}
.sa-trems-pages-wrap .breadcrumpart ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.sa-trems-pages-wrap .breadcrumpart ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #3C3C3C;
}
.sa-trems-pages-wrap .breadcrumpart ul li + li:before {
  content: "/";
  display: inline-flex;
  margin: 0 0.4rem;
}
.sa-trems-pages-wrap .breadcrumpart ul li a {
  text-decoration: none;
  color: #000;
}

.sa-full-content {
  border-radius: 1.8rem;
  background: #FFF;
  padding: 3rem 2rem;
  min-height: 100%;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
}

.sa-full-content,
.sa-trems-content {
  border-radius: 1.8rem;
  background: #FFF;
  padding: 3rem 2rem;
  min-height: 100%;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
}
@media (max-width: 767px) {
  .sa-full-content,
  .sa-trems-content {
    padding: 1rem 0rem;
  }
}
.sa-full-content h1,
.sa-full-content h2,
.sa-full-content h3,
.sa-full-content h4,
.sa-trems-content h1,
.sa-trems-content h2,
.sa-trems-content h3,
.sa-trems-content h4 {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 767px) {
  .sa-full-content h1,
  .sa-full-content h2,
  .sa-full-content h3,
  .sa-full-content h4,
  .sa-trems-content h1,
  .sa-trems-content h2,
  .sa-trems-content h3,
  .sa-trems-content h4 {
    line-height: 1.6;
  }
}
.sa-full-content h1,
.sa-trems-content h1 {
  font-size: 2.8rem;
}
@media (max-width: 767px) {
  .sa-full-content h1,
  .sa-trems-content h1 {
    font-size: 2.4rem;
  }
}
.sa-full-content h2,
.sa-trems-content h2 {
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  .sa-full-content h2,
  .sa-trems-content h2 {
    font-size: 1.8rem;
  }
}
.sa-full-content h3,
.sa-trems-content h3 {
  font-size: 2rem;
}
@media (max-width: 767px) {
  .sa-full-content h3,
  .sa-trems-content h3 {
    font-size: 1.6rem;
  }
}
.sa-full-content h4,
.sa-trems-content h4 {
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .sa-full-content h4,
  .sa-trems-content h4 {
    font-size: 1.4rem;
  }
}
.sa-full-content p,
.sa-trems-content p {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
}
.sa-full-content a,
.sa-trems-content a {
  text-decoration: none;
}
.sa-full-content ul,
.sa-full-content ol,
.sa-trems-content ul,
.sa-trems-content ol {
  padding: 0;
  margin: 0;
}
.sa-full-content ul li,
.sa-full-content ol li,
.sa-trems-content ul li,
.sa-trems-content ol li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 1.6rem;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
}

.choose-any-cards {
  padding: 0;
  margin: 0;
}
.choose-any-cards li {
  list-style: none;
}
.choose-any-cards li .sa-list-box {
  cursor: pointer;
}
.choose-any-cards li .sa-list-box.active .sa-list-box__image {
  position: relative;
}
.choose-any-cards li .sa-list-box.active .sa-list-box__image::before, .choose-any-cards li .sa-list-box.active .sa-list-box__image::after {
  content: "";
  position: absolute;
}
.choose-any-cards li .sa-list-box.active .sa-list-box__image::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
}
.choose-any-cards li .sa-list-box.active .sa-list-box__image::after {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNkw2IDExTDE2IDEiIHN0cm9rZT0iIzRDMzlBQyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.choose-any-model .comman-popup-inner .popup-model-body {
  padding-top: 0;
}
.choose-any-model .model-footer-panel .btn {
  width: 100%;
}

.following-panel {
  padding: 0;
  margin: 0;
}
.following-panel li {
  list-style: none;
  border-bottom: 1px solid rgba(235, 235, 235, 0.92);
}
.following-panel li .follow-box-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 0;
}
.following-panel li .follow-box-panel .imgbxpt {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  margin-right: 1rem;
  flex-shrink: 0;
}
.following-panel li .follow-box-panel .imgbxpt img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.following-panel li .follow-box-panel .follow-content {
  padding: 0;
  margin: 0;
}
.following-panel li .follow-box-panel .follow-content h2 {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  margin-bottom: 0.2rem;
}
.following-panel li .follow-box-panel .follow-content p {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  margin: 0;
}
.following-panel li .follow-box-panel .follow-right-btn {
  margin-left: auto;
  padding-right: 1rem;
}
.following-panel li .follow-box-panel .follow-right-btn .btn {
  padding: 0 1.8rem;
  font-size: 1.3rem;
  height: 3.7rem;
  box-shadow: none;
}
.following-panel li .follow-box-panel .follow-right-btn .btn.active {
  background-color: #eeedf3;
  border-color: #eeedf3;
  color: #4c39ac;
  box-shadow: none;
}

.sa-error-recoard {
  padding: 0;
  margin: 0;
  padding: 3rem 0 8rem;
}
.sa-error-recoard .sa-error-img {
  padding: 0;
  margin: 0 0 2.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sa-error-recoard .sa-error-img img {
  max-width: 100%;
}
.sa-error-recoard .sa-error-data {
  padding: 0;
  justify-content: center;
  display: flex;
  flex-flow: column;
}
.sa-error-recoard .sa-error-data h2 {
  color: #000;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  margin: 0 0 0.7rem;
}
.sa-error-recoard .sa-error-data p {
  color: #848484;
  text-align: center;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}
.sa-error-recoard .sa-error-data .btn {
  width: auto;
  display: inline-flex;
  margin: 3rem auto 0;
  padding: 0 4rem;
}

.notification-model .comman-popup-inner .popup-model-body {
  padding-top: 0;
}

.notifyfollower {
  padding: 0;
  margin: 0;
}
.notifyfollower li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #ebebeb;
}
.notifyfollower li:first-child .notificationrow {
  padding-top: 0;
}
.notifyfollower li .notificationrow {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.2rem 0;
  position: relative;
}
.notifyfollower li .notificationrow .notifyimg {
  flex-shrink: 0;
  margin-right: 1.2rem;
  display: inline-flex;
}
.notifyfollower li .notificationrow .notifyimg img {
  max-width: 3.4rem;
}
.notifyfollower li .notificationrow .notifycontent {
  display: inline-flex;
  padding: 0;
  margin: 0;
}
.notifyfollower li .notificationrow .notifycontent .notifytitle {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
}
.notifyfollower li .notificationrow .notifycontent .notifytitle b {
  color: #000;
  font-weight: normal;
}
.notifyfollower li .notificationrow .notifycontent .notifytitle span {
  margin-top: 0.6rem;
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.module-gallery {
  width: 100%;
  position: sticky;
  top: 10rem;
}
.module-gallery .slider-wrapper {
  display: flex;
  max-height: 46.8rem;
}
@media (max-width: 767px) {
  .module-gallery .slider-wrapper {
    height: 24rem;
  }
}
.module-gallery .sldnavside {
  position: relative;
}
.module-gallery .prevarrow,
.module-gallery .nextarrow {
  z-index: 1;
  height: 4rem;
  width: 4rem;
  background-color: #FFFFFF;
  opacity: 1;
  transition: opacity 300ms ease;
  font-size: 1.5em;
  color: #0F1425;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0.4rem 1.6rem rgba(0, 0, 0, 0.06);
  left: 4.8rem;
  border: none;
  outline: none;
  position: absolute;
}
@media (max-width: 767px) {
  .module-gallery .prevarrow,
  .module-gallery .nextarrow {
    left: 1rem;
  }
}
.module-gallery .prevarrow:before,
.module-gallery .nextarrow:before {
  content: "";
}
.module-gallery .prevarrow:hover,
.module-gallery .nextarrow:hover {
  opacity: 1;
}
.module-gallery .prevarrow.slick-disabled,
.module-gallery .nextarrow.slick-disabled {
  display: none !important;
}
.module-gallery .prevarrow {
  right: unset;
  top: -1.8rem;
}
.module-gallery .nextarrow {
  top: unset;
  right: unset;
  bottom: -1.8rem;
}
.module-gallery .slider-thumb {
  width: 15.4rem;
  padding: 0rem 1.8rem;
  flex-shrink: 0;
  padding-left: 0;
  margin: 0;
  max-height: 46.8rem;
  overflow: hidden;
}
@media (max-width: 767px) {
  .module-gallery .slider-thumb {
    width: 8rem;
  }
}
.module-gallery .slider-thumb li {
  padding-bottom: 1.6rem;
}
.module-gallery .slider-thumb li img {
  width: 100%;
  border-radius: 1.4rem;
  height: 14.3rem;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .module-gallery .slider-thumb li img {
    height: 6.2rem;
    border-radius: 0.5rem;
  }
}
.module-gallery .slider-preview {
  margin: 0;
}
.module-gallery .slider-preview li {
  width: 100%;
}
.module-gallery .slider-preview li img {
  width: 100%;
  border-radius: 1.4rem;
  height: 46.8rem;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .module-gallery .slider-preview li img {
    height: 22.5rem;
  }
}

/* details css start */
.detailscontent {
  padding: 0;
  margin: 0;
  padding-bottom: 3.5rem;
}
.detailscontent .titlehead {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
}
.detailscontent .titlehead h2 {
  margin: 0;
  margin-right: auto;
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 2.8rem;
  font-weight: 400;
  padding-right: 5rem;
}
@media (max-width: 1199px) {
  .detailscontent .titlehead h2 {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  .detailscontent .titlehead h2 {
    font-size: 1.8rem;
    padding-right: 2rem;
    line-height: 1.6;
  }
}
.detailscontent .titlehead .wishlistpt {
  margin-left: auto;
  margin-bottom: auto;
}
.detailscontent .listviewsrow {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -0.8rem 1.6rem;
  flex-wrap: wrap;
}
.detailscontent .listviewsrow li {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 33.33%;
  padding: 0 0.8rem;
}
.detailscontent .listviewsrow li .cardbox {
  border-radius: 1rem;
  background: -moz-linear-gradient(-45deg, rgb(253, 239, 225) 0%, rgba(254, 249, 244, 0) 51%, rgba(255, 255, 255, 0) 81%);
  background: -webkit-linear-gradient(-45deg, rgb(253, 239, 225) 0%, rgba(254, 249, 244, 0) 51%, rgba(255, 255, 255, 0) 81%);
  background: linear-gradient(135deg, rgb(253, 239, 225) 0%, rgba(254, 249, 244, 0) 51%, rgba(255, 255, 255, 0) 81%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdefe1", endColorstr="#00ffffff", GradientType=1);
  padding: 1.6rem 1.4rem;
  min-height: 9.7rem;
}
.detailscontent .listviewsrow li .cardbox .iconimg {
  margin-bottom: 0.6rem;
}
.detailscontent .listviewsrow li .cardbox .iconimg img {
  height: 2.2rem;
  width: auto;
}
.detailscontent .listviewsrow li .cardbox .cardcontent {
  padding: 0;
  margin: 0;
}
.detailscontent .listviewsrow li .cardbox .cardcontent span {
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0 0.4rem;
}
.detailscontent .listviewsrow li .cardbox .cardcontent p {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}

.listsinglebar {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdefe1+0,fdefe1+100&1+0,0+100 */
  background: -moz-linear-gradient(left, rgb(253, 239, 225) 0%, rgba(253, 239, 225, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(253, 239, 225)), color-stop(100%, rgba(253, 239, 225, 0)));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(left, rgb(253, 239, 225) 0%, rgba(253, 239, 225, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(left, rgb(253, 239, 225) 0%, rgba(253, 239, 225, 0) 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(left, rgb(253, 239, 225) 0%, rgba(253, 239, 225, 0) 100%);
  /* IE10 preview */
  background: linear-gradient(to right, rgb(253, 239, 225) 0%, rgba(253, 239, 225, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdefe1", endColorstr="#00fdefe1", GradientType=1);
  /* IE6-9 */
  padding: 0.5rem 1.4rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.listsinglebar .listviewsrow {
  display: flex;
  margin-bottom: 0;
}
.listsinglebar .listviewsrow li {
  flex: 0 0 auto;
  width: auto;
}
.listsinglebar .listviewsrow li .cardbox {
  width: 100%;
  min-height: auto;
  background: transparent;
  padding: 0rem;
  display: flex;
  align-items: center;
}
.listsinglebar .listviewsrow li .cardbox .iconimg {
  margin-bottom: 0;
  margin-right: 0.5rem;
  line-height: 0;
  flex-shrink: 0;
}
.listsinglebar .listviewsrow li .cardbox .iconimg img {
  width: 1.6rem;
}
.listsinglebar .listviewsrow li .cardbox .cardcontent p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.6rem;
  color: #969696;
}

.swopperrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 1.2rem 0;
  position: relative;
}
.swopperrow .swopper-left {
  display: inline-flex;
  align-items: center;
}
.swopperrow .swopper-left .swopperimg {
  display: inline-flex;
  flex-shrink: 0;
  width: 5.3rem;
  height: 5.3rem;
  position: relative;
  margin-right: 1rem;
}
.swopperrow .swopper-left .swopperimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.swopperrow .swopper-left .swoppercontent {
  padding: 0;
  margin: 0;
}
.swopperrow .swopper-left .swoppercontent .swoppertitle {
  padding: 0;
  margin: 0 0 0.2rem;
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  display: flex;
  align-items: center;
}
.swopperrow .swopper-left .swoppercontent .swoppertitle a {
  color: #000000;
  text-decoration: none;
}
.swopperrow .swopper-left .swoppercontent p {
  margin: 0;
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.25rem;
  /* 173.077% */
  letter-spacing: 0.03rem;
}
.swopperrow .swopper-right {
  margin-left: auto;
  text-align: right;
}
.swopperrow .swopper-right .datetimept {
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
}
.swopperrow .swopper-right .swoppername {
  color: #4C39AC;
  font-family: "Celias", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.03rem;
  text-decoration: none;
}

.smtitle {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.swopper-outer {
  padding: 1.6rem 0;
  border-top: 1px solid #EBEBEB;
}
.swopper-outer .smtitle {
  color: #000;
  font-family: "Celias", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
.swopper-outer .btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
.swopper-outer .btn-row .btn {
  display: inline-flex;
  width: auto;
  padding: 0 4rem;
  min-width: 47.8%;
}
@media (max-width: 1199px) {
  .swopper-outer .btn-row .btn {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  .swopper-outer .btn-row .btn {
    padding: 0 1.2rem;
  }
}
.swopper-outer .btn-row .btn + .btn {
  margin-left: 2rem;
}
@media (max-width: 1199px) {
  .swopper-outer .btn-row .btn + .btn {
    margin-left: 1rem;
  }
}
.swopper-outer .btn-row.btn-bar-top-border {
  padding-top: 1.6rem;
  border-top: 1px solid #EBEBEB;
}
.swopper-outer.description-outer .headpanel {
  margin: 0 0 1.2rem;
}
.swopper-outer.description-outer .headpanel p {
  color: #848484;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0rem;
}
.swopper-outer.description-outer .boxlike {
  width: 100%;
  margin: 0;
  display: flex;
  padding: 1.2rem 2rem;
  border-radius: 1rem;
  background: #F4F4F4;
  flex-flow: column;
}
.swopper-outer.description-outer .boxlike label {
  color: #3C3C3C;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 0 1rem;
  width: 100%;
}
.swopper-outer.description-outer .boxlike label span {
  color: #CECECE;
}
.swopper-outer.description-outer .boxlike label .infowrap {
  margin-left: 0.5rem;
  cursor: pointer;
}
.swopper-outer.description-outer .boxlike .form-control {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
  padding: 0 1.5rem;
}
.swopper-outer.description-outer .boxlike textarea.form-control {
  height: auto;
  padding: 1.1rem 1.5rem;
}
.swopper-outer.description-outer .boxlike .the-count-time {
  padding: 0;
  margin: 0.2rem 0 0;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #bfc1c5;
}
.swopper-outer.description-outer .boxlike .select2 {
  width: 100% !important;
}
.swopper-outer.description-outer .boxlike .select2.select2-hidden-accessible {
  display: none;
}
.swopper-outer.description-outer .boxlike .select2 .select2-selection {
  height: 5rem;
  border-radius: 1rem;
  border: solid 0.1rem #e7e7e7;
  background-color: #ffffff;
}
.swopper-outer.description-outer .boxlike .select2 .select2-selection .select2-selection__rendered {
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
  color: #8c8c8c;
  font-size: 1.3rem;
  font-family: "Celias", sans-serif;
}
.swopper-outer.description-outer .boxlike .select2 .select2-selection .select2-selection__arrow {
  height: 4.8rem;
  width: 4rem;
}
.swopper-outer.description-outer .boxlike .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
}
.swopper-outer.description-outer .boxlike .select2 .select2-selection .select2-selection__arrow b:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C2.17157 3.5621 2.82843 4.4379 4 6C4 6 5.82843 3.5621 7 2" stroke="black" stroke-miterlimit="3.99933" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 8px;
  height: 8px;
  position: absolute;
}
.swopper-outer.description-outer .boxlike .tagslist {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.swopper-outer.description-outer .boxlike .tagslist li {
  padding: 0;
  margin: 0;
}
.swopper-outer.description-outer .boxlike .tagslist li + li {
  margin-left: 1rem;
}
.swopper-outer.description-outer .boxlike .tagslist li .tagsbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.7rem;
  padding: 0rem 1.6rem;
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  border-radius: 1.2rem;
  background: #FFF;
}

.repott-share-foot {
  padding: 1rem 0;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
}
.repott-share-foot a {
  text-decoration: none;
  color: #000000;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.repott-share-foot a + a {
  margin-left: 2rem;
}
.repott-share-foot a i {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 1rem;
}

.categorymenu-sidebar {
  padding: 0;
  margin: 0;
  flex-flow: column;
}
.categorymenu-sidebar li {
  padding: 0;
  text-align: center;
  border-bottom: solid 0.1rem #EEF2F6;
}
.categorymenu-sidebar li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.99);
  padding: 1.2rem 0;
  font-family: "Celias", sans-serif;
  height: 100%;
  position: relative;
  width: 100%;
  justify-content: flex-start;
}
.categorymenu-sidebar li a .iconpt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: #eef2f6;
  margin-bottom: 0rem;
  border-radius: 1.4rem;
  overflow: hidden;
  font-size: 2.1rem;
  color: #9cafbc;
  margin-right: 1rem;
}
.categorymenu-sidebar li a .iconpt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.categorymenu-sidebar li a.moreicons .iconpt img {
  object-fit: contain;
  width: auto;
  height: auto;
  width: 1.5rem;
  height: 1.5rem;
}
.categorymenu-sidebar li a:hover {
  color: #4c39ac;
}
.categorymenu-sidebar li:first-child a {
  padding-top: 0;
}

.dtls-title-inside {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .dtls-title-inside {
    margin-bottom: 1.5rem;
  }
}
.dtls-title-inside h2 {
  font-family: "Celias", sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.6rem;
  margin: 0;
  color: #000000;
}
@media (max-width: 767px) {
  .dtls-title-inside h2 {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.unsticky {
  position: unset !important;
}

.sticky-sidebar {
  position: sticky;
  top: 12rem;
}

.main-overview-outer {
  margin-top: 4rem;
}
@media (max-width: 767px) {
  .main-overview-outer {
    margin-top: 2rem;
  }
}

.outer-card-container {
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.11);
  border: 0.1rem solid #EBEBEB;
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 2rem;
}
.outer-card-container h2 {
  color: #000000;
  font-family: Celias;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.6rem;
  margin-bottom: 2rem;
}

.card-details-list {
  margin-bottom: 0;
}
.card-details-list li {
  list-style: none;
}
.card-details-list li p {
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.6rem;
  margin: 0;
  color: #969696;
}
.card-details-list li .card-each-list {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
}
.card-details-list li .card-each-list .icon-box {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  display: flex;
}
.card-details-list li .card-each-list .icon-box img {
  width: 1.4rem;
  height: auto;
}
.card-details-list li .card-each-list .label-text {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #969696;
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.card-details-list li .card-each-list .label-text .label {
  font-size: 1.4rem;
}
.card-details-list li .card-each-list .label-text .value-text {
  position: relative;
  font-weight: 500;
  text-align: left;
  color: #000;
  margin-left: auto;
}
.card-details-list li .card-each-list .label-text .value-text.label-left {
  margin: inherit;
}
.card-details-list li .card-each-list .label-text .value-text .tagspt {
  font-size: 1.4rem;
}
.card-details-list li .card-each-list .label-text .value-text .tagspt.green {
  color: #4CB050;
}
.card-details-list li .card-each-list .label-text .value-text .tagspt.yellow {
  color: #FF9700;
}
.card-details-list li .card-each-list .label-text .value-text .tagspt.red {
  color: #F44236;
}

.request-callback-model form {
  height: 100%;
}

.get-in-touch-wrap {
  padding: 0;
  margin: 0;
}
.get-in-touch-wrap .top-logo-title {
  margin-bottom: 2rem;
}
.get-in-touch-wrap .top-logo-title h2 {
  font-family: "Celias", sans-serif;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 3.35rem;
  color: #24272C;
}
@media (max-width: 767px) {
  .get-in-touch-wrap .top-logo-title h2 {
    font-size: 2.2rem;
  }
}
.get-in-touch-wrap .top-logo-title h2 span {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.6rem;
}
@media (max-width: 767px) {
  .get-in-touch-wrap .top-logo-title h2 span {
    line-height: 1.6;
  }
}
.get-in-touch-wrap .form-group {
  margin-bottom: 2rem;
}
.get-in-touch-wrap .form-group.checkbox-group {
  margin: 0;
}
.get-in-touch-wrap .form-group.checkbox-group input {
  display: none;
}
.get-in-touch-wrap .form-group.checkbox-group input + label {
  color: #24272C;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
}
.get-in-touch-wrap .form-group.checkbox-group input + label a {
  color: #24272C;
  text-decoration: underline;
}
.get-in-touch-wrap .form-group.checkbox-group input + label a:hover {
  color: #4C39AC;
}
.get-in-touch-wrap .form-group.checkbox-group input + label span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: relative;
  border: solid 0.1rem rgba(0, 0, 0, 0.5);
  border-radius: 0.4rem;
  flex-shrink: 0;
}
.get-in-touch-wrap .form-group.checkbox-group input + label span::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #4C39AC;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out 0.3s;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjMzMzI1IDQuMTk0NDhMNC40NDk2OSA3LjMwNTZMMTAuNjY2NiAxLjA4MzM3IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
  background-position: center center;
  background-repeat: no-repeat;
}
.get-in-touch-wrap .form-group.checkbox-group input:checked + label span {
  background-color: #4C39AC;
  border-color: #4C39AC;
}
.get-in-touch-wrap .form-group.checkbox-group input:checked + label span:before {
  opacity: 1;
  visibility: visible;
}
.get-in-touch-wrap .form-group.checkbox-group input:checked:disabled + label {
  cursor: default;
}
.get-in-touch-wrap .form-group.checkbox-group input:checked:disabled + label span {
  cursor: default;
  background-color: #c2bbe7;
  border-color: #c2bbe7;
}
.get-in-touch-wrap .form-group.checkbox-group input:checked:disabled + label span::before {
  background-color: #c2bbe7;
  border-color: #c2bbe7;
}
.get-in-touch-wrap .form-group.checkbox-group input:disabled + label {
  cursor: default;
}
.get-in-touch-wrap .form-group.checkbox-group input:disabled + label span {
  cursor: default;
  background-color: #ededed;
  border-color: #dddddd;
}
.get-in-touch-wrap .form-group textarea.form-control {
  height: 10rem;
  padding-top: 1rem;
  resize: none;
  font-family: "Celias", sans-serif;
}
.get-in-touch-wrap .btn_row {
  align-items: center;
}
@media (max-width: 767px) {
  .get-in-touch-wrap .btn_row {
    flex-flow: column;
  }
}
.get-in-touch-wrap .btn_row .btn {
  padding: 0 1.5rem;
  height: 4.4rem;
}
@media (max-width: 767px) {
  .get-in-touch-wrap .btn_row .btn {
    margin-top: 2rem;
    width: 100%;
  }
}

.contact-section-wrap {
  padding-bottom: 2rem;
}

.contact-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .contact-image {
    margin-bottom: 2rem;
  }
}
.contact-image img {
  max-width: 100%;
}

.faq-section-wrap .title {
  font-family: "Celias", sans-serif;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 3.9rem;
  color: #24272C;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .faq-section-wrap .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
}

.accordion-container .outer-accordion-box {
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border: 0.1rem solid #F0F0F0;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
}
.accordion-container .accordion-title {
  position: relative;
  margin: 0;
  padding: 2rem 5rem 2rem 2rem;
  cursor: pointer;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.87rem;
  color: #24272C;
}
@media (max-width: 767px) {
  .accordion-container .accordion-title {
    font-size: 1.3rem;
  }
}
.accordion-container .accordion-title:after {
  content: "";
  position: absolute;
  top: 1.8rem;
  right: 1.6rem;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 1.66667C17.3383 1.66667 18.8333 3.16167 18.8333 5V15C18.8333 16.8383 17.3383 18.3333 15.5 18.3333H5.5C3.66167 18.3333 2.16667 16.8383 2.16667 15V5C2.16667 3.16167 3.66167 1.66667 5.5 1.66667H15.5ZM15.5 0H5.5C4.17392 0 2.90215 0.526784 1.96447 1.46447C1.02678 2.40215 0.5 3.67392 0.5 5L0.5 15C0.5 16.3261 1.02678 17.5979 1.96447 18.5355C2.90215 19.4732 4.17392 20 5.5 20H15.5C16.8261 20 18.0979 19.4732 19.0355 18.5355C19.9732 17.5979 20.5 16.3261 20.5 15V5C20.5 3.67392 19.9732 2.40215 19.0355 1.46447C18.0979 0.526784 16.8261 0 15.5 0Z' fill='%2324272C'/%3E%3Cpath d='M10.4993 15C10.2783 15 10.0664 14.9122 9.91009 14.7559C9.75381 14.5996 9.66602 14.3877 9.66602 14.1667V5.83333C9.66602 5.61232 9.75381 5.40036 9.91009 5.24408C10.0664 5.0878 10.2783 5 10.4993 5C10.7204 5 10.9323 5.0878 11.0886 5.24408C11.2449 5.40036 11.3327 5.61232 11.3327 5.83333V14.1667C11.3327 14.3877 11.2449 14.5996 11.0886 14.7559C10.9323 14.9122 10.7204 15 10.4993 15Z' fill='%2324272C'/%3E%3Cpath d='M5.5 10.0001C5.5 9.77907 5.5878 9.56711 5.74408 9.41083C5.90036 9.25455 6.11232 9.16675 6.33333 9.16675H14.6667C14.8877 9.16675 15.0996 9.25455 15.2559 9.41083C15.4122 9.56711 15.5 9.77907 15.5 10.0001C15.5 10.2211 15.4122 10.4331 15.2559 10.5893C15.0996 10.7456 14.8877 10.8334 14.6667 10.8334H6.33333C6.11232 10.8334 5.90036 10.7456 5.74408 10.5893C5.5878 10.4331 5.5 10.2211 5.5 10.0001Z' fill='%2324272C'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  background-size: contain;
}
.accordion-container .accordion-title.active:after {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 1.66667C17.3383 1.66667 18.8333 3.16167 18.8333 5V15C18.8333 16.8383 17.3383 18.3333 15.5 18.3333H5.5C3.66167 18.3333 2.16667 16.8383 2.16667 15V5C2.16667 3.16167 3.66167 1.66667 5.5 1.66667H15.5ZM15.5 0H5.5C4.17392 0 2.90215 0.526784 1.96447 1.46447C1.02678 2.40215 0.5 3.67392 0.5 5L0.5 15C0.5 16.3261 1.02678 17.5979 1.96447 18.5355C2.90215 19.4732 4.17392 20 5.5 20H15.5C16.8261 20 18.0979 19.4732 19.0355 18.5355C19.9732 17.5979 20.5 16.3261 20.5 15V5C20.5 3.67392 19.9732 2.40215 19.0355 1.46447C18.0979 0.526784 16.8261 0 15.5 0Z' fill='%2324272C'/%3E%3Cpath d='M5.5 9.99996C5.5 9.77895 5.5878 9.56698 5.74408 9.4107C5.90036 9.25442 6.11232 9.16663 6.33333 9.16663H14.6667C14.8877 9.16663 15.0996 9.25442 15.2559 9.4107C15.4122 9.56698 15.5 9.77895 15.5 9.99996C15.5 10.221 15.4122 10.4329 15.2559 10.5892C15.0996 10.7455 14.8877 10.8333 14.6667 10.8333H6.33333C6.11232 10.8333 5.90036 10.7455 5.74408 10.5892C5.5878 10.4329 5.5 10.221 5.5 9.99996Z' fill='%2324272C'/%3E%3C/svg%3E%0A");
}

.accordion-content {
  padding: 0 2rem 2rem 2rem;
  display: none;
  transition: max-height 0.3s ease-out;
}
.accordion-content p {
  margin: 0;
  font-family: "Celias", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.87rem;
  color: #24272C;
}
@media (max-width: 767px) {
  .accordion-content p {
    font-size: 1.3rem;
  }
}
.accordion-content p + p {
  margin-top: 1rem;
}

.accordion-container {
  width: 100%;
  margin: 0 auto;
}

.accordion-container .accordion-title.open::after {
  content: "";
  position: absolute;
  top: 15px;
  border: 8px solid transparent;
  border-bottom-color: #fff;
}

/*CSS for CodePen*/
.whats-app-fixed-btn {
  position: fixed;
  margin: 0.4rem;
  padding: 0rem;
  outline: 0;
  right: 1rem;
  bottom: 1rem;
  z-index: 999;
  color: #fff;
  border: none;
  border-radius: 3rem;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  width: 5.2rem;
  height: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(37, 211, 102);
  outline: none;
  cursor: pointer;
}
.whats-app-fixed-btn .zc-whats-notifi-badge {
  font-size: 1.4rem;
  height: 2rem;
  position: absolute;
  border-radius: 2rem;
  transform: translateY(-50%);
  background-color: red;
  color: #fff;
  padding: 0 0.8rem;
  top: 0.2rem;
  right: 0;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.multi-cat-box-wrap {
  max-width: 51.6rem;
  width: 100%;
  margin: 0 auto;
}
.multi-cat-box-wrap .list-ofmain-cat {
  padding: 0;
  margin: 0 -3rem;
  display: flex;
  flex-wrap: wrap;
}
.multi-cat-box-wrap .list-ofmain-cat li {
  padding: 0 1.5rem;
  list-style: none;
  outline: none;
  width: 33.33%;
  margin-bottom: 3rem;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root {
  width: 100%;
  height: 15.3rem;
  padding: 1.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: ease-in-out 0.3s;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root .main-card-box {
  padding: 1.7rem 0 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  flex-flow: column;
  align-items: center;
  text-decoration: none;
  outline: none;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root .main-card-box i {
  margin-bottom: 1.2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root .main-card-box i img {
  max-width: 100%;
  transition: all 0.3s ease-in-out 0.1s;
  object-fit: contain;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root .main-card-box .cat-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root:hover {
  background-color: #F7F7F7;
}
.multi-cat-box-wrap .list-ofmain-cat li .multi-box-root:hover .main-card-box i img {
  transform: scale(1.08) translateY(-5px);
}
.multi-cat-box-wrap.mobile-category-wrap {
  max-width: 100%;
  width: 100%;
  padding-top: 10px;
  display: none;
}
@media (max-width: 991px) {
  .multi-cat-box-wrap.mobile-category-wrap {
    display: flex;
  }
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat {
  margin: 0;
  width: 100%;
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat li {
  padding: 5px 5px;
  margin: 0;
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat li .multi-box-root {
  padding: 15px 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-height: 110px;
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat li .multi-box-root .main-card-box {
  padding: 0;
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat li .multi-box-root .main-card-box i {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}
.multi-cat-box-wrap.mobile-category-wrap .list-ofmain-cat li .multi-box-root .main-card-box .cat-title {
  font-size: 12px;
  text-transform: none;
  color: #2B2D2E;
}
.multi-cat-box-wrap.mobile-category-wrap .mob-subcategory {
  flex-flow: column;
}
.multi-cat-box-wrap.mobile-category-wrap .mob-subcategory .mob-list-title {
  display: flex;
  align-items: center;
}
.multi-cat-box-wrap.mobile-category-wrap .mob-subcategory .mob-list-title .back-btn {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
}
.multi-cat-box-wrap.mobile-category-wrap .mob-subcategory .mob-list-title h2 {
  font-size: 14px;
  display: inline-flex;
  padding-left: 10px;
  vertical-align: middle;
  color: #000;
  margin: 0;
}

.list-of-sub-category {
  padding: 0;
  margin: 0rem;
  display: flex;
  width: 100%;
}
.list-of-sub-category ul {
  padding: 0;
  margin: 0rem;
  display: flex;
  width: 100%;
  flex-flow: column;
}
.list-of-sub-category ul li {
  padding: 0rem;
  list-style: none;
  outline: none;
  width: 100%;
  margin-bottom: 0dvi;
  border-bottom: solid 0.1rem #EBEBEB;
}
.list-of-sub-category ul li a {
  display: inline-flex;
  width: 100%;
  outline: none;
  font-size: 1.4rem;
  font-family: "Celias", sans-serif;
  font-weight: normal;
  color: #000000;
  padding: 1.3rem 0;
  text-decoration: none;
  transition: ease-in-out 0.3s;
}
.list-of-sub-category ul li a .arrow-icon {
  margin-left: auto;
  flex-shrink: 0;
}
.list-of-sub-category ul li a .arrow-icon .default-icon {
  display: flex;
}
.list-of-sub-category ul li a .arrow-icon .hover-icon {
  display: none;
}
.list-of-sub-category ul li:hover a {
  color: #4C39AC;
}
.list-of-sub-category ul li:hover a .arrow-icon .default-icon {
  display: none;
}
.list-of-sub-category ul li:hover a .arrow-icon .hover-icon {
  display: flex;
}

.sa-header-container .sa-menu ul .dropdown-parents {
  position: relative;
}
.sa-header-container .sa-menu ul .dropdown-parents .shadowbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 0.3s;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu {
  top: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) 0rem 1.6rem 1.6rem 0rem;
  border-bottom-left-radius: 6px;
  background: white;
  width: 25rem;
  z-index: 1;
  padding-bottom: 1rem;
  box-sizing: content-box;
  height: 42.5rem;
  position: absolute;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul {
  padding: 0;
  margin: 0;
  flex-flow: column;
  height: auto;
  box-shadow: none;
  border: none;
  background-color: transparent;
  position: static;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul::before, .sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul::after {
  display: none;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li {
  padding: 0;
  margin: 0;
  border: none;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li a {
  text-decoration: none;
  cursor: pointer;
  color: #2b2d2e;
  font-size: 1.4rem;
  padding: 0.6rem 1.2rem 0.6rem 3.2rem;
  font-weight: 400;
  width: 100%;
  position: relative;
  line-height: 1.5;
  pointer-events: auto;
  align-items: flex-start;
  transition: none;
  border: none;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li a:after {
  display: none;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li a.title-name {
  font-weight: 500;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li:hover a {
  color: #4c39ac;
  text-decoration: none;
  font-weight: 500;
  background: #f6f7f8;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu .sub-expanded-menu {
  position: absolute;
  display: none;
  left: 100%;
  top: 0px;
  padding: 16px;
  background: rgb(246, 247, 248);
  overflow: auto;
  border-bottom-right-radius: 6px;
  border-left: 0.1rem solid rgb(238, 240, 241);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu .sub-expanded-menu li a {
  padding: 0;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu {
  display: block;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul::before, .sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul::after {
  display: none;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul li {
  padding: 0;
  margin: 0;
  border: none;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  padding-bottom: 0.5rem;
  flex: 0 1 auto;
  padding-right: 1rem;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul li a {
  font-size: 1.4rem;
  font-weight: normal;
  color: #626465;
  line-height: 1.4;
  padding-left: 0;
  font-weight: 300;
}
.sa-header-container .sa-menu ul .dropdown-parents .dropdown-web-menu ul li.expanded-menu:hover .sub-expanded-menu ul li:hover a {
  color: #4c39ac;
  text-decoration: none;
  text-decoration: underline;
}
.sa-header-container .sa-menu ul .dropdown-parents:hover a {
  color: #4c39ac;
}
.sa-header-container .sa-menu ul .dropdown-parents:hover .shadowbg {
  visibility: visible;
  opacity: 1;
}