.filterselect {
  padding: 0;
  box-shadow: none;
}
.filterselect > div {
  box-shadow: none !important;
  min-height: 5rem !important;
  margin: 0 !important;
}
.filterselect > div .css-1u9des2-indicatorSeparator {
  display: none !important;
}
.sa-pagination {
  list-style-type: none;
}

.active-page > a {
  background-color: #144db4;
  border-color: #144db4;
  color: #fff;
}
